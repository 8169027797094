import React from "react";
import { useEffect, useState, ReactNode } from "react";
import Firebase from "../../../config/Firebase";
import Logo from "../../../images/logo.png";
// import { useStyles } from "./styles";

interface MaintenanceModeProps {
  children: ReactNode;
}

function MaintenanceMode({ children }: MaintenanceModeProps) {
  // NOTE: Usar el useStyle aca rompe el ThemeProvider
  const [isInMaintenance, setIsInMaintenance] = useState(false);

  useEffect(() => {
    const remoteConfig = Firebase.getRemoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 0;

    remoteConfig.fetchAndActivate().then(() => {
      const maintenanceMode = remoteConfig.getValue("maintenance_mode");
      setIsInMaintenance(maintenanceMode.asBoolean());
    });
  }, []);

  if (isInMaintenance) {
    return (
      <div
        style={{
          flex: 1,
          alignContent: "center",
          justifyItems: "center",
          height: "100vh",
          width: "100vw",
          backgroundColor: "var(--xiclos-bg)",
        }}>
        <img src={Logo} alt="Xiclos" />
        <h1>🛠️ Sitio en Mantenimiento</h1>
        <p>Estamos realizando mejoras. Por favor, vuelve más tarde.</p>
      </div>
    );
  }

  return <>{children}</>;
}

export default MaintenanceMode;
