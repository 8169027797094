import { USE_MOCKS, API, HEADERS } from "./APIConnector";
import { BannerMock } from "./mocks/BannerMocks";
import Firebase from "../config/Firebase";

//TODO Ver de permitir filtrar los banners que consulta
//Consulta los banners
export const getBanners = async signal => {
  if (USE_MOCKS) {
    let banners = [BannerMock];
    for (let i = 2; i < 10; i++) {
      banners.push({
        ...BannerMock,
        bn_id: i,
        bn_order: i,
        bn_button_name: "PROBAR" + i,
      });
    }
    return banners;
  }
  const data = await fetch(`${API}/banners`, {
    method: "GET",
    headers: HEADERS(),
    signal,
    cache: "default",
  });
  const result = await data.json();
  return result.data;
};

export const deleteBanner = async id => {
  const response = await fetch(`${API}/delete/banner`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      bn_id: id,
    }),
  });
  const data = await response.json();
  return data;
};

export const createBanner = async body => {
  const dataBanner = await fetch(`${API}/banner`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  });
  const response = await dataBanner.json();
  const id = response.data.newId;
  await fetch(`${API}/update/banner/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      bn_order: id,
    }),
  });
  return [id, response];
};

export const deleteBannerImage = async imageName => {
  // const imageName = `${id}` + image.name.match(/\.\w+$/g)[0];

  const storageRef = await Firebase.getStorageRef();

  const fileRef = await storageRef.child(`/banners/${imageName}`);

  await fileRef.delete();
};

export const uploadBannerImage = async (id, image) => {
  const imageName = `${id}` + image.name.match(/\.\w+$/g)[0];

  const storageRef = await Firebase.getStorageRef();

  const fileRef = await storageRef.child(`/banners/${imageName}`);

  await fileRef.put(image);

  const fileUrl = await fileRef.getDownloadURL();

  return fileUrl;
};

export const updateBannerImage = async (id, url) => {
  const data = await fetch(`${API}/update/banner/image/${id}`, {
    method: "PUT",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("Authorization"),
      U: localStorage.getItem("U"),
    },
    body: JSON.stringify({
      bn_image: url,
    }),
  });
  return await data.json();
};
