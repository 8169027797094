/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import "../css/explore.scss";

import Loader from "../components/Loader";
import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import Footer from "../components/Footer";

import { listAction } from "../actions/MyListAction";
import { getMyList } from "../provider/MyListServices";
import { fetchGenres } from "../provider/GenreService";
import { fetchCountries } from "../provider/CountryServices";
import { fetchExploreGenres } from "../provider/ExploreServices";
import { Grid, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { trimString } from "../common/Util";
import ScrollButton from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Poster from "../components/V2/Poster/Poster";

const Explore = () => {
  const dispatch = useDispatch();
  const ml = useSelector((state: any) => state.ml);
  const [loading, setLoading] = useState(false);
  const [allGenres, setAllGenres] = useState<any>([]);
  const [genres, setGenres] = useState<any>([]);
  const [allTopics, setAllTopics] = useState([]);
  const [topics, setTopics] = useState<any>([]);
  const [allCountries, setAllCountries] = useState([]);
  const [countries, setCountries] = useState([]);

  const [movies, setMovies] = useState<any>([]);
  const [url, setUrl] = useState<any>({ genres: [], countries: [] });
  const [isMovies, setIsMovies] = useState(true);

  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  const [seeMoreButton, setSeeMoreButton] = useState({
    genres: true,
    topics: true,
    countries: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // TODO: QUITAR ESTO
  useEffect(() => {
    getMyList().then(result => {
      if (result && result.count_movies !== ml.count_movies) {
        dispatch(listAction(result));
      }
    });
  }, [dispatch, ml.count_movies]);

  useEffect(() => {
    fetchGenres({ type: "genre", minMovies: "3" }).then(data => {
      setAllGenres(data.data);
      setGenres(sortPerQuantity(data.data).slice(0, 5));
    });
    fetchGenres({ type: "topic", minMovies: "3" }).then(data => {
      setAllTopics(data.data);
      setTopics(sortPerQuantity(data.data).slice(0, 5));
    });
    fetchCountries().then(data => {
      setAllCountries(data.data);
      setCountries(sortPerQuantity(data.data).slice(0, 5));
    });
  }, []);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const sortPerQuantity = (arr: any) => {
    return arr.sort((a: any, b: any) => {
      return b.movies.length - a.movies.length;
    });
  };

  const seeMore = (arg: any) => {
    switch (arg) {
      case "genres":
        setSeeMoreButton(prevState => ({ ...prevState, genres: false }));
        setGenres(
          allGenres.sort((a: any, b: any) => {
            if (a.ge_name > b.ge_name) {
              return 1;
            }
            if (a.ge_name < b.ge_name) {
              return -1;
            }
            return 0;
          }),
        );
        break;
      case "topics":
        setSeeMoreButton(prevState => ({ ...prevState, topics: false }));
        setTopics(
          allTopics.sort((a: any, b: any) => {
            if (a.ge_name > b.ge_name) {
              return 1;
            }
            if (a.ge_name < b.ge_name) {
              return -1;
            }
            return 0;
          }),
        );
        break;
      case "countries":
        setSeeMoreButton(prevState => ({ ...prevState, countries: false }));
        setCountries(
          allCountries.sort((a: any, b: any) => {
            if (a.co_name > b.co_name) {
              return 1;
            }
            if (a.co_name < b.co_name) {
              return -1;
            }
            return 0;
          }),
        );
        break;
      default:
        break;
    }
  };

  const seeLess = (arg: any) => {
    switch (arg) {
      case "genres":
        setSeeMoreButton(prevState => ({ ...prevState, genres: true }));
        setGenres(sortPerQuantity(allGenres).slice(0, 5));
        break;
      case "topics":
        setSeeMoreButton(prevState => ({ ...prevState, topics: true }));
        setTopics(sortPerQuantity(allTopics).slice(0, 5));
        break;
      case "countries":
        setSeeMoreButton(prevState => ({ ...prevState, countries: true }));
        setCountries(sortPerQuantity(allCountries).slice(0, 5));
        break;
      default:
        break;
    }
  };

  function getMovieData(item: any) {
    const directorsArray: any[] = [];
    item.directors.forEach((item: any) => {
      directorsArray.push(item.di_name + " " + item.di_lastname);
    });
    let directors = "";
    if (directorsArray.length > 0) {
      directors = directorsArray.join(", ");
    }

    const genreArray: any[] = [];
    item.genre.forEach((item: any) => {
      genreArray.push(item.ge_name);
    });
    let genre = "";
    if (genreArray.length > 0) {
      genre = genreArray.join(", ");
    }

    const actorArray: any[] = [];
    item.actor.forEach((item: any) => {
      actorArray.push(item.ac_name + " " + item.ac_lastname);
    });
    let actors = "";
    if (actorArray.length > 0) {
      actors = actorArray.join(", ");
    }

    const countriesArray: any[] = [];
    item.country.forEach((item: any) => {
      countriesArray.push(item.co_name);
    });
    let countries = "";
    if (countriesArray.length > 0) {
      countries = countriesArray.join(", ");
    }

    const data = {
      mo_id: item.mo_id,
      mo_title: item.mo_title,
      mo_thumbnail: item.mo_thumbnail,
      mo_image_fullsize: item.mo_image_fullsize,
      mo_description: trimString(item.mo_description, 115),
      mo_year: item.mo_year,
      mo_imdb_rating: item.mo_imdb_rating,
      mo_mpa_rating: item.mo_mpa_rating,
      mo_duration: item.mo_duration,
      directors: directors,
      genre: trimString(genre, 24),
      actors: trimString(actors, 50),
      countries: trimString(countries, 50),
    };

    return data;
  }

  useEffect(() => {
    let genreString = "";
    let countriesString = "";
    url.genres.forEach((genre: any) => {
      if (genreString === "") {
        genreString = `${genre}`;
      } else {
        genreString = `${genreString},${genre}`;
      }
    });
    url.countries.forEach((country: any) => {
      if (countriesString === "") {
        countriesString = `${country}`;
      } else {
        countriesString = `${countriesString},${country}`;
      }
    });
    if (genreString === "" && countriesString === "") {
      setMovies([]);
      setLoading(false);
      return;
    }
    fetchExploreGenres({
      genres: genreString,
      countries: countriesString,
    })
      .then(data => {
        setLoading(false);
        const moviesFormatted = data?.data.map((item: any) => {
          return getMovieData(item);
        });
        if (moviesFormatted.length === 0) {
          setIsMovies(false);
        }
        setMovies(moviesFormatted);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsMovies(false);
        setLoading(false);
      });
  }, [url.countries, url.genres]);

  const showMoviesByGenre = (id: any, type: any) => {
    setLoading(true);
    setMovies([]);
    switch (type) {
      case "genre": {
        const filterGenres = url.genres.filter((genre: any) => {
          return genre !== id;
        });
        if (filterGenres.length === url.genres.length) {
          setUrl((previousState: any) => ({
            ...previousState,
            genres: [...url.genres, id],
          }));
        } else {
          setUrl((previousState: any) => ({
            ...previousState,
            genres: filterGenres,
          }));
        }
        break;
      }
      case "country": {
        const filterCountries = url.countries.filter((country: any) => {
          return country !== id;
        });
        if (filterCountries.length === url.countries.length) {
          setUrl((previousState: any) => ({
            ...previousState,
            countries: [...url.countries, id],
          }));
        } else {
          setUrl((previousState: any) => ({
            ...previousState,
            countries: filterCountries,
          }));
        }
        break;
      }
      case "clean": {
        setUrl((previousState: any) => ({
          ...previousState,
          genres: [],
          countries: [],
        }));
        break;
      }
      default:
        break;
    }
  };

  const handleModal = (action: any) => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrió un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrió un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="explore" id="explore">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explorar - Xiclos</title>
        <meta name="description" content="Explorar películas" />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Explorar"}
        icon={"../images/icons/xiclos-icon-yellow@2x.png"}
        descriptionText={undefined}
      />
      <section className="first-section wrapper-movie-interest pt-4">
        <div className="container-fluid px-0 px-lg-5">
          <Grid container>
            <Grid container item xs={12} sm={12} md={6} padding={2}>
              <Grid item xs>
                <p className="w-100 pb-4">
                  Se podrán combinar géneros, temas y países para hacer una
                  selección más específica
                </p>
                <div id="explore-onchange" className="d-none"></div>
                <div className="col-12 explore-tax-title">Genero</div>
                <div className="col-12 explore-tax-list">
                  <ul id="tax-genre">
                    {genres.length !== 0 ? (
                      <Fragment>
                        {genres.map((genre: any) => (
                          <li
                            key={genre.ge_id}
                            onClick={() => {
                              showMoviesByGenre(genre.ge_id, "genre");
                            }}
                            className={
                              url.genres.includes(genre.ge_id)
                                ? "explore-tax-item explore-tax-active"
                                : "explore-tax-item"
                            }>
                            {genre.ge_name}
                          </li>
                        ))}
                        {seeMoreButton.genres ? (
                          <button
                            className="see-more"
                            onClick={() => seeMore("genres")}>
                            Ver +
                          </button>
                        ) : (
                          <button
                            className="see-more"
                            onClick={() => seeLess("genres")}>
                            Ver -
                          </button>
                        )}
                      </Fragment>
                    ) : (
                      <div className="divLoading">
                        <Loader show />
                      </div>
                    )}
                  </ul>
                </div>
                <div className="col-12 explore-tax-title">Temas</div>
                <div className="col-12 explore-tax-list">
                  <ul id="tax-topic">
                    {topics.length !== 0 ? (
                      <Fragment>
                        {topics.map((topic: any) => (
                          <li
                            key={topic.ge_id}
                            className={
                              url.genres.includes(topic.ge_id)
                                ? "explore-tax-item explore-tax-active"
                                : "explore-tax-item"
                            }
                            onClick={() =>
                              showMoviesByGenre(topic.ge_id, "genre")
                            }>
                            {topic.ge_name}
                          </li>
                        ))}
                        {seeMoreButton.topics ? (
                          <button
                            className="see-more"
                            onClick={() => seeMore("topics")}>
                            Ver +
                          </button>
                        ) : (
                          <button
                            className="see-more"
                            onClick={() => seeLess("topics")}>
                            Ver -
                          </button>
                        )}
                      </Fragment>
                    ) : (
                      <div className="divLoading">
                        <Loader show />
                      </div>
                    )}
                  </ul>
                </div>
                <div className="col-12 explore-tax-title">País</div>
                <div className="col-12 explore-tax-list">
                  <ul id="tax-country">
                    {countries.length !== 0 ? (
                      <Fragment>
                        {countries.map((country: any) => (
                          <li
                            key={country.co_id}
                            className={
                              url.countries.includes(country.co_id)
                                ? "explore-tax-item explore-tax-active"
                                : "explore-tax-item"
                            }
                            onClick={() =>
                              showMoviesByGenre(country.co_id, "country")
                            }>
                            {country.co_name}
                          </li>
                        ))}
                        {seeMoreButton.countries ? (
                          <button
                            className="see-more"
                            onClick={() => seeMore("countries")}>
                            Ver +
                          </button>
                        ) : (
                          <button
                            className="see-more"
                            onClick={() => {
                              seeLess("countries");
                            }}>
                            Ver -
                          </button>
                        )}
                      </Fragment>
                    ) : (
                      <div className="divLoading">
                        <Loader show />
                      </div>
                    )}
                  </ul>
                </div>
                <button
                  id="explore-clean"
                  onClick={() => {
                    setIsMovies(true);
                    showMoviesByGenre(0, "clean");
                  }}
                  className="explore-clean">
                  Limpiar
                </button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              spacing={3}
              padding={2}
              style={{ alignContent: "flex-start" }}>
              {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-wrap vortex-slides-pull"> */}
              {movies.length > 0 ? (
                movies.map((movie: any) => (
                  <Grid
                    container
                    item
                    style={{ maxHeight: 340 }}
                    xs={6}
                    md={4}
                    lg={4}
                    xl={3}
                    key={movie.mo_id}>
                    {/* <PosterPull
                      id={movie.mo_id}
                      item={movie}
                      handleModal={handleModal}
                      customClass="col-6 col-sm-4 col-md-6 col-xl-4 vortex-film-explore explore-max-height-posters"
                    /> */}
                    <Poster movie={movie} handleModal={handleModal} />
                  </Grid>
                ))
              ) : isMovies ? (
                <p className="w-100 text-center">
                  Seleccioná alguno de los filtros para iniciar la exploración.
                </p>
              ) : (
                <p className="w-100 text-center">
                  No se encontraron resultados para esa exploración.
                </p>
              )}
              {loading && (
                <div className="divLoading">
                  <Loader show />
                </div>
              )}
            </Grid>

            {/* </div> */}
          </Grid>
        </div>
      </section>
      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type as any}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Explore;
