import React, { Fragment, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  Button,
  Grid,
  Paper,
  TextField,
  CircularProgress,
  FormHelperText,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import Room from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import XiclosCyan from "../images/icons/xiclos-icon-cyan@3x.png";

import { scrollToTop, validateEmail } from "../common/Util";
import { API, HEADERS } from "../provider/APIConnector";
import "../css/Contact.scss";
import { useSelector } from "react-redux";
import NavigationTitle from "../components/NavigationTitle";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    padding: "10px 20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "20px 0",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    textAlign: "center",
    borderRadius: "5px",
    padding: theme.spacing(2, 4, 3),
    "& #transition-modal-title": {
      color: "var(--xiclos-red)",
      fontSize: "20px",
      fontFamily: "var(--xiclos-font-helv-bold)",
    },
    "& #transition-modal-description": {
      color: "var(--xiclos-text)",
      paddingTop: "10px",
      margin: 0,
    },
  },
}));

const Contact = ({ authChecked }) => {
  const classes = useStyles();
  const captcha = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [captchaValidation, setCaptchaValidation] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
    captcha: false,
  });
  const [loading, setLoading] = useState(false);
  let usr = useSelector(state => state.usr);

  useEffect(function toTop() {
    let isMounted = true;
    if (isMounted) {
      scrollToTop();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(
    function setUserInForm() {
      let isMounted = true;
      if (usr && usr.user && authChecked && isMounted) {
        setName(usr.user.us_name + usr.user.us_lastname);
        setEmail(usr.user.us_email);
      }
      return () => {
        isMounted = false;
      };
    },
    [authChecked, usr],
  );

  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setErrors(state => ({ ...state, captcha: false }));
      setCaptchaValidation(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let errorsCheck = false;
    if (!captchaValidation) {
      errorsCheck = true;
      setErrors(state => ({ ...state, captcha: true }));
    }
    if (name === "" || name?.length < 3) {
      errorsCheck = true;
      setErrors(state => ({ ...state, name: true }));
    }
    if (email === "" || !validateEmail(email)) {
      errorsCheck = true;
      setErrors(state => ({ ...state, email: true }));
    }
    if (message === "" || message?.length < 10) {
      errorsCheck = true;
      setErrors(state => ({ ...state, message: true }));
    }

    if (errorsCheck) {
      setLoading(false);
      return null;
    }

    let resp = await fetch(`${API}/contact`, {
      method: "POST",
      headers: { ...HEADERS(), "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        name: name,
        message: message,
      }),
    });

    let data = await resp.json();
    setLoading(false);
    if (data.success) {
      setOpen(true);
    }

    setName("");
    setEmail("");
    setMessage("");
    captcha.current.reset();
  };
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contacto - Xiclos</title>
        <meta name="description" content="Página de contacto" />
      </Helmet>
      <Header />
      <div className="contact iq-main-slider">
        <NavigationTitle title={"Contacto"} icon={XiclosCyan} />
        <Grid container justifyContent="center" className="paper-container">
          <Grid item xs={12} md={11} lg={8}>
            <Paper className="paper-content">
              <Grid container spacing={4}>
                <Grid container style={{ padding: 10 }}>
                  <SendIcon
                    style={{ marginRight: 10, color: "var(--xiclos-bg)" }}
                  />
                  <h5
                    style={{
                      color: "var(--xiclos-text)",
                      fontFamily: "var(--xiclos-font-helv-bold)",
                    }}>
                    Su consulta nos ayuda a mejorar el servicio
                  </h5>
                </Grid>
                <Grid item xs container spacing={4}>
                  <Grid item sm={12} md={9}>
                    <TextField
                      error={errors.name}
                      id="contact-name"
                      onChange={e => {
                        setName(e.target.value);
                        errors.name &&
                          setErrors(state => ({ ...state, name: false }));
                      }}
                      variant="outlined"
                      value={name}
                      fullWidth
                      label="Nombre completo"
                      helperText={
                        errors.name &&
                        "El nombre está vacío (mínimo 3 caracteres)"
                      }
                      color="primary"
                      type="text"
                      autoFocus={localStorage.getItem("U") ? false : true}
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      error={errors.email}
                      id="contact-email"
                      onChange={e => {
                        setEmail(e.target.value);
                        errors.email &&
                          setErrors(state => ({ ...state, email: false }));
                      }}
                      variant="outlined"
                      value={email}
                      fullWidth
                      label="Email"
                      helperText={errors.email && "El email es incorrecto"}
                      color="primary"
                      type="email"
                      style={{ marginBottom: 20 }}
                    />
                    <TextField
                      error={errors.message}
                      id="contact-message"
                      onChange={e => {
                        setMessage(e.target.value);
                        errors.message &&
                          setErrors(state => ({ ...state, message: false }));
                      }}
                      variant="outlined"
                      value={message}
                      fullWidth
                      autoFocus={localStorage.getItem("U") ? true : false}
                      minRows={4}
                      maxRows={4}
                      multiline
                      helperText={
                        errors.message &&
                        "El mensaje está vacío (mínimo 10 caracteres)"
                      }
                      label="Consulta"
                      color="primary"
                      type="text"
                      style={{ marginBottom: 20 }}
                    />
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6}>
                        <ReCAPTCHA
                          sitekey="6LeL12gcAAAAAHMAL_uSM1fcPUlO4vxpqaIEoXXZ"
                          onChange={handleCaptcha}
                          ref={captcha}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ textAlign: "right" }}>
                        <Button
                          disableElevation
                          color="primary"
                          variant="contained"
                          disabled={
                            loading ||
                            errors.captcha ||
                            errors.message ||
                            errors.name ||
                            errors.email
                          }
                          onClick={handleSubmit}
                          className={classes.button}>
                          {loading ? (
                            <CircularProgress size={16} />
                          ) : (
                            "Enviar mensaje"
                          )}
                        </Button>
                      </Grid>
                      {errors.captcha && (
                        <FormHelperText error={errors.captcha}>
                          El captcha es incorrecto
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={3}
                    style={{
                      fontFamily: "var(--xiclos-font-helv-normal)",
                      color: "var(--xiclos-text)",
                    }}>
                    <p>
                      Somos <b>Xiclos</b>. Desde Argentina, ofreciendo el mejor
                      cine a todo el mundo.
                    </p>
                    <ul style={{ padding: 0, listStyle: "none" }}>
                      <li style={{ paddingBottom: 5 }}>
                        <Room
                          style={{
                            marginRight: 10,
                            fontSize: "1.2em",
                            color: "var(--xiclos-bg)",
                          }}
                        />
                        Buenos Aires, Argentina
                      </li>
                      <li style={{ paddingBottom: 5 }}>
                        <EmailIcon
                          style={{
                            marginRight: 10,
                            fontSize: "1.2em",
                            color: "var(--xiclos-bg)",
                          }}
                        />
                        info@xiclos.com
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Mensaje recibido</h2>
              <p id="transition-modal-description">
                Gracias por ponerte en contacto con <b>Xiclos</b>
              </p>
            </div>
          </Fade>
        </Modal>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Contact;
