import {
  ACT_MYLIST_CHANGE,
  ACT_MYLIST_ADDMOVIE,
  ACT_MYLIST_REMOVE,
} from "../actions/ActionTypes";

const initialState = {
  movies: [],
  count_movies: 0,
};

export default function (
  state = initialState,
  action: { type: string; slider: any; movie: any; id: any },
) {
  if (action.type === ACT_MYLIST_CHANGE) {
    return { ...state, ...action.slider };
  }

  if (action.type === ACT_MYLIST_ADDMOVIE) {
    return { ...state, movies: [action.movie, ...state.movies] };
  }

  if (action.type === ACT_MYLIST_REMOVE) {
    const movies = state.movies.filter((value: any, index, arr) => {
      return value?.mo_id !== action.id;
    });

    return { ...state, movies };
  }
  return state;
}
