import firebase from "firebase";
import "firebase/storage";
import "firebase/remote-config";
import { PAYMENT_ACCEPTED, STATUS_USER_INACTIVE } from "../common/constant";

let firebaseInstance: firebase.app.App | null = null;
let unsubscribeSnapshotPayment: (() => void) | null = null;

const getFirebaseInstance = (): firebase.app.App => {
  if (!firebaseInstance) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    if (firebase.apps[0]) {
      firebaseInstance = firebase.app();
    } else {
      firebaseInstance = firebase.initializeApp(firebaseConfig);
    }
  }
  return firebaseInstance as firebase.app.App;
};

const getToken = () => {
  return getFirebaseInstance().auth().currentUser?.getIdToken(true);
};

const signOut = () => {
  return getFirebaseInstance().auth().signOut();
};

const onAuthStateChanged = (
  callback:
    | firebase.Observer<firebase.User | null, Error>
    | ((user: firebase.User | null) => void),
) => {
  getFirebaseInstance().auth().onAuthStateChanged(callback);
};

const currentUser = () => {
  return getFirebaseInstance().auth().currentUser;
};

const onSnapshotPayment = (
  user: { us_email: string; us_suscription_status: string },
  onChange: (data: firebase.firestore.DocumentData | undefined) => void,
) => {
  const email = user.us_email;
  const status = user.us_suscription_status;
  if (user.us_suscription_status !== "free") {
    getFirebaseInstance()
      .firestore()
      .collection("users")
      .doc(email)
      .get()
      .then((snap: firebase.firestore.DocumentSnapshot) => {
        const pu = snap.data();
        unsubscribeSnapshotPayment = getFirebaseInstance()
          .firestore()
          .collection("users")
          .doc(email)
          .collection("payments")
          .doc(pu?.current)
          .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
            const payment = snapshot.data();
            onChange(payment);
            if (
              payment?.payment_state === PAYMENT_ACCEPTED &&
              status !== STATUS_USER_INACTIVE
            ) {
              if (unsubscribeSnapshotPayment) {
                unsubscribeSnapshotPayment();
              }
              unsubscribeSnapshotPayment = null;
            }
          });
      });
  }
};

const unsubscribePayment = () => {
  if (unsubscribeSnapshotPayment) {
    unsubscribeSnapshotPayment();
  }
};

const updatePassword = async (oldPassword: string, newPassword: string) => {
  const user = getFirebaseInstance().auth().currentUser;
  if (user) {
    const provider = firebase.auth.EmailAuthProvider;
    const cred = provider.credential(user.email!, oldPassword);

    try {
      await user.reauthenticateWithCredential(cred);
      try {
        return await user.updatePassword(newPassword);
      } catch (err) {
        return err;
      }
    } catch (err) {
      return err;
    }
  }
};

const getStorageRef = async () => {
  return await getFirebaseInstance().storage().ref();
};

const getRemoteConfig = () => {
  return getFirebaseInstance().remoteConfig();
};

export default {
  signOut: signOut,
  onAuthStateChanged: onAuthStateChanged,
  onSnapshotPayment: onSnapshotPayment,
  unsubscribePayment: unsubscribePayment,
  getToken: getToken,
  currentUser: currentUser,
  updatePassword: updatePassword,
  getStorageRef: getStorageRef,
  getRemoteConfig: getRemoteConfig,
};
