import { useState, useEffect } from "react";
import "../css/sync-device.scss";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import XiclosRed from "../images/icons/xiclos-icon-red.png";
import NavigationTitle from "../components/NavigationTitle";
import useWidth from "../hooks/useWidth";
import { Helmet } from "react-helmet";
import { getDevices, removeDevices, syncDevice } from "../provider/UserService";
import { useFormik } from "formik";

const SyncDevice = () => {
  const width = useWidth();
  const [devices, setDevices] = useState<any | null>(null);
  const [badCode, setBadCode] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const { values, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: ({ code }) => onSyncDevice(code),
  });

  useEffect(() => {
    getDevices()
      .then(data => {
        setDevices(data?.data);
        setLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setLoading(false);
      });
  }, [refresh]);

  const errorCallback = () => {
    setSyncing(false);
  };

  const syncDeviceCallback = (deviceData: any) => {
    if (deviceData) {
      resetForm();
      setRefresh(prev => !prev);
    } else {
      setBadCode(true);
    }
    setSyncing(false);
  };

  const onSyncDevice = async (code: string) => {
    if (!syncing) {
      if (devices && code && code.length === 6) {
        try {
          setLoading(true);
          setSyncing(true);
          const deviceData = await syncDevice(
            code.trim().toLowerCase(),
            `TV ${devices.length + 1}`,
          );
          syncDeviceCallback(deviceData?.data);
          setLoading(false);
        } catch (error: any) {
          errorCallback();
          setLoading(false);
        }
      } else {
        setBadCode(true);
      }
    }
  };

  const onDeleteDevice = async (device: any) => {
    try {
      setLoading(true);
      await removeDevices(device.ud_id);
      setRefresh(prev => !prev);
    } catch (err) {
      setLoading(false);
      errorCallback();
    }
  };

  const renderItems = () => {
    if (!devices || devices.length === 0) {
      return (
        <ListItem>
          <ListItemText primary="No encontramos ningún dispositivo vinculado"></ListItemText>
        </ListItem>
      );
    }

    return devices.map((device: any, index: any) => {
      return (
        <ListItem divider key={"devices" + index}>
          <ListItemText
            primary={device.ud_device_name}
            secondary={
              device.Device &&
              `${device.Device.dv_device_id} (${device.Device.dv_platform})`
            }></ListItemText>
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="Eliminar"
              onClick={() => {
                onDeleteDevice(device);
              }}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <div className="sync-device">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sincronizar dispositivo - Xiclos</title>
        <meta
          name="description"
          content="Sincronizar dispositivos/Smart tv a la cuenta del usuario"
        />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Sincronizar Dispositivos"}
        icon={XiclosRed}
        descriptionText={undefined}
      />
      <div className="main-content">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={9} lg={7}>
            <Paper className="paper-card">
              <Grid container spacing={4}>
                <Grid item xs={12} md={5} lg={5}>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          value={values.code}
                          variant="outlined"
                          error={badCode}
                          label="Código"
                          name="code"
                          fullWidth
                          inputProps={{ maxLength: 6 }}
                          onChange={evt => {
                            handleChange(evt);
                            setBadCode(false);
                          }}
                          helperText={
                            badCode ? "Revise el código ingresado" : ""
                          }
                        />
                      </Grid>
                      <Grid item xs>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={syncing}>
                          <Typography style={{ marginLeft: 4 }}>
                            Vincular
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                  <ListSubheader>Dispositivos</ListSubheader>
                  {isError ? (
                    <ListItem>
                      <ListItemText primary="Ocurrió un error al buscar los dispositivos vinculados"></ListItemText>
                    </ListItem>
                  ) : loading ? (
                    <Grid
                      container
                      style={{ height: 240 }}
                      justifyContent="center"
                      alignContent="center">
                      <CircularProgress size={48} />
                    </Grid>
                  ) : (
                    <List className={width === "xs" ? "" : "list-devices"}>
                      {renderItems()}
                    </List>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default SyncDevice;
