import { API, HEADERS } from "./APIConnector";

export const getUserData = async userProvided => {
  const endpoint = `${API}/admin/user/${userProvided.uid}`;
  const data = await fetch(endpoint, { method: "GET", headers: HEADERS() });
  return data.json();
};
export const syncDevice = async (code, name) => {
  const headers = await HEADERS();
  headers["Content-Type"] = "application/json";
  // console.log("SYN DEVICE", code, name)
  const endpoint = `${API}/sync-device`;
  const body = JSON.stringify({ code: code, name: name });
  // console.log("BODY", body)
  const data = await fetch(endpoint, {
    method: "POST",
    headers: headers,
    body: body,
  });
  return data.json();
};

export const removeDevices = async id => {
  const headers = await HEADERS();
  headers["Content-Type"] = "application/json";
  const endpoint = `${API}/device`;
  const body = JSON.stringify({ id: id });
  // console.log("getDevices HEADERS", endpoint,  headers)
  const data = await fetch(endpoint, {
    method: "DELETE",
    headers: headers,
    body: body,
  });
  return data.json();
};

export const getDevices = async () => {
  const headers = await HEADERS();
  const endpoint = `${API}/devices`;
  // console.log("getDevices HEADERS", endpoint,  headers)
  const data = await fetch(endpoint, { method: "GET", headers: headers });
  return data.json();
};

export const updatePayment = async body => {
  let request = {
    method: "POST",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  let resp = await fetch(API + "/user/updatePayment", request);
  return resp.json();
};

export const getUsers = async () => {
  const response = await fetch(API + "/users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const updateSubscription = async body => {
  const response = await fetch(API + "/update/user/subscription", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export const updateNotes = async body => {
  const response = await fetch(API + "/update/user/note", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export const updatePassword = async body => {
  const response = await fetch(API + "/user/change-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export const deleteUser = async id => {
  const response = await fetch(API + `/user/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const changeState = async body => {
  const response = await fetch(API + `/user/change-state`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export const verifyFreeUserExpDate = async id => {
  const response = await fetch(API + `/user/verify-exp-date/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const createFreeUser = async body => {
  const response = await fetch(API + `/admin/user/free`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};
