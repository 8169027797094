import React from "react";
import { Button, Grid } from "@mui/material";
import useWidth from "../hooks/useWidth";
import { useHistory } from "react-router-dom";

function FooterLogin({ copyRightText, className }) {
  const history = useHistory();
  const width = useWidth();

  return (
    <footer
      className={`container-fluid ${className || ""}`}
      style={{ zIndex: 30 }}>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          md={6}
          alignItems="center"
          justifyContent={width !== "xs" ? "flex-start" : "center"}>
          <p className="text-footer mb-0">{copyRightText}</p>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          justifyContent={width !== "xs" ? "flex-end" : "center"}>
          <Button
            size="large"
            onClick={() => history.push("/contact")}
            style={{ color: "#d1d0cf" }}>
            <p className="text-footer mb-0">Contacto</p>
          </Button>
        </Grid>
      </Grid>
    </footer>
  );
}

export default FooterLogin;
