import {
  ACT_MYLIST_CHANGE,
  ACT_MYLIST_ADDMOVIE,
  ACT_MYLIST_REMOVE,
} from "./ActionTypes";

import { addList, removeList } from "../provider/MyListServices";

export function addMovieListAction(movie, cbSuccess, cbFail) {
  return dispatch => {
    addList(movie.mo_id)
      .then(res => {
        // console.log('res', res)
        if (res.code == 200) {
          dispatch({
            type: ACT_MYLIST_ADDMOVIE,
            movie: movie,
          });
          cbSuccess && cbSuccess();
        } else {
          //console.log("La película ya existe en mi lista");
          cbFail && cbFail();
        }
      })
      .catch(err => {
        //console.log("Hubo un error al agregar la película");
        cbFail && cbFail();
      });
  };
}

export function removeMovieListAction(movie, cbSuccess, cbFail) {
  return dispatch => {
    removeList(movie.mo_id)
      .then(res => {
        if (res.code == 200) {
          dispatch({
            type: ACT_MYLIST_REMOVE,
            id: movie.mo_id,
          });
          cbSuccess && cbSuccess();
        } else {
          console.log("No se pudo borrar..");
          cbFail && cbFail();
        }
      })
      .catch(err => {
        console.log("Hubo un error al borrar la película de la lista..");
        cbFail && cbFail();
      });
  };
}

export function listAction(slider) {
  return dispatch => {
    // console.log(slider);
    dispatch({
      type: ACT_MYLIST_CHANGE,
      slider,
    });
  };
}
