import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMovieListAction,
  removeMovieListAction,
} from "../actions/MyListAction";
import { IconButton, Button, Grid } from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";
import { getDeviceType } from "../common/Util";
import { LARGE_DEVICE } from "../common/constant";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import useWidth from "../hooks/useWidth";

function toString(obj, type) {
  let string = "";
  switch (type) {
    case "directors":
      for (let i = 0; i < obj.length; i++) {
        if (obj.length - 1 === i) {
          string += `${obj[i].di_name} ${obj[i].di_lastname}.`;
        } else {
          string += `${obj[i].di_name} ${obj[i].di_lastname}, `;
        }
      }
      return string;
    case "actors":
      for (let i = 0; i < obj.length; i++) {
        if (obj.length - 1 === i) {
          string += `${obj[i].ac_name} ${obj[i].ac_lastname}.`;
        } else {
          string += `${obj[i].ac_name} ${obj[i].ac_lastname}, `;
        }
      }
      return string;
    case "genres":
      for (let i = 0; i < obj.length; i++) {
        if (obj.length - 1 === i) {
          string += `${obj[i].ge_name}.`;
        } else {
          string += `${obj[i].ge_name}, `;
        }
      }
      return string;
    case "countries":
      for (let i = 0; i < obj.length; i++) {
        if (obj.length - 1 === i) {
          string += `${obj[i].co_name}.`;
        } else {
          string += `${obj[i].co_name}, `;
        }
      }
      return string;
    default:
      return string;
  }
}

export default function BannerShowDetails({ movie, handleModal, freeMode }) {
  const width = useWidth();
  let usr = useSelector(state => state.usr);
  let ml = useSelector(state => state.ml);
  const dispatch = useDispatch();
  const history = useHistory();
  // console.log(movie);
  let inMyList = false;

  ml.movies.forEach(el => {
    if (el.mo_id === movie.mo_id) {
      inMyList = true;
    }
  });
  return (
    <div
      className="slider m-0 p-0"
      style={{
        backgroundImage: `url(${
          getDeviceType() === LARGE_DEVICE
            ? movie.mo_image_fullsize
            : movie.mo_thumbnail.replace("_150x260", "")
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "60% auto",
        backgroundPosition: "right",
      }}>
      <div className="slide slick-bg p-0">
        <div className="container-fluid position-relative p-0">
          <div className="slider-inner p-0">
            <div className="container-fluid position-relative p-0">
              <div className="slider-inner">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-12 col-md-12">
                    <IconButton
                      onClick={() => {
                        history.goBack();
                      }}
                      style={{ paddingLeft: 0 }}>
                      <BackIcon
                        style={{
                          color: "white",
                          fontSize: "3rem",
                          marginBottom: 30,
                        }}
                      />
                    </IconButton>
                    {movie.mo_imdb_rating && (
                      <div
                        className="extra-data-imdb show-movie-detail-imdb"
                        style={{ color: "white" }}>
                        IMDb {movie.mo_imdb_rating}
                      </div>
                    )}
                    <h1 className="movie-title">{movie.mo_title}</h1>
                    <p className="show-movie-detail-text">
                      {toString(movie.country, "countries")}
                    </p>
                    <div className="d-flex align-items-center show-movie-detail-text">
                      <span className="space">{movie.mo_year}</span>
                      <span className="space">{movie.mo_duration} min</span>
                      <span className="badge p-2 show-movie-detail-rating">
                        {movie.mo_mpa_rating}
                      </span>
                    </div>
                    <div className="detail-text">
                      <p>Título original: {movie.mo_original_title}</p>
                      <p>{movie.mo_description}</p>
                    </div>
                    <div className="d-flex align-items-center r-mb-23">
                      <Grid container>
                        <button
                          style={{
                            marginBottom: width === "xs" ? 10 : 0,
                            marginRight: width === "xs" ? 0 : 10,
                          }}
                          onClick={() => {
                            usr.user
                              ? history.push({
                                  pathname: "/watch",
                                  search: `?id=${movie.mo_id}`,
                                })
                              : freeMode
                              ? history.push({
                                  pathname: "/watch-festival",
                                  search: `?id=${movie.mo_id}`,
                                })
                              : history.push("/login");
                          }}
                          className="btn btn-hover">
                          <i className="fa fa-play mr-2" aria-hidden="true"></i>
                          Ver ahora
                        </button>
                        {usr.user ? (
                          inMyList ? (
                            <Button
                              color="primary"
                              variant="outlined"
                              startIcon={<RemoveIcon />}
                              onClick={() => {
                                dispatch(
                                  removeMovieListAction(
                                    movie,
                                    () => handleModal("remove"),
                                    () => handleModal("errorRemove"),
                                  ),
                                );
                              }}>
                              Eliminar de mi lista
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={() => {
                                dispatch(
                                  addMovieListAction(
                                    movie,
                                    () => handleModal("add"),
                                    () => handleModal("errorAdd"),
                                  ),
                                );
                              }}>
                              Añadir a mi lista
                            </Button>
                          )
                        ) : null}
                      </Grid>
                      {/* <div className={usr.user ? "share d-md-block" : `share d-md-block d-lg-flex ml-3`}>
                        <span><i className="ri-share-fill"></i></span>
                        <div className="share-box">
                            <div className="d-flex align-items-center">
                                <a href="#" className="share-ico"><i className="ri-facebook-fill"></i></a>
                                <a href="#" className="share-ico"><i className="ri-twitter-fill"></i></a>
                                <a href="#" className="share-ico"><i className="ri-links-fill"></i></a>
                            </div>
                        </div>
                        </div> */}
                    </div>
                    <div className="show-movie-detail-text pt-2">
                      <p>
                        Dirección
                        <span className="show-movie-info">
                          {" "}
                          {toString(movie.directors, "directors")}
                        </span>
                      </p>
                      <p>
                        Reparto
                        <span className="show-movie-info">
                          {" "}
                          {toString(movie.actor, "actors")}
                        </span>
                      </p>
                      <p>
                        Género
                        <span className="show-movie-info">
                          {" "}
                          {toString(movie.genre, "genres")}
                        </span>
                      </p>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
