import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { STATUS_USER_INACTIVE } from "../common/constant";
import Dialog from "@mui/material/Dialog";
import { useHistory } from "react-router-dom";

const renderContent = user => {
  if (user && user.us_suscription_status === STATUS_USER_INACTIVE) {
    return (
      <div className="modal-body">
        <div className="modal-payment-msg">
          Tu suscripción no está activa, revise su medio de pago
        </div>
      </div>
    );
  }

  return (
    <div className="modal-body">
      <div>Estamos procesando su pago.</div>
    </div>
  );
};

const shouldCheckPayment = () => {
  const noCheckPage = [
    "manage-profile.html",
    "login.html",
    "/profile",
    "sign-up.html",
    "home-no-user",
    "/",
    "/home",
    "/explore",
    "/details",
  ];
  let pathName = window.location.pathname;
  return !noCheckPage.includes(pathName);
};

const ModalPayment = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isPageChange, setIsPageChange] = useState(false);
  const user = useSelector(state => state.usr.user);

  useEffect(() => {
    setOpen(false);
    if (shouldCheckPayment()) {
      if (user?.us_suscription_status === STATUS_USER_INACTIVE) {
        setOpen(true);
      }
    }
  }, [user?.us_suscription_status, isPageChange]);

  const onClose = () => {
    if (user.us_suscription_status === STATUS_USER_INACTIVE) {
      window.location.href = "/profile";
    }
    setOpen(false);
  };

  useEffect(() => {
    const unlisten = history.listen(e => {
      setIsPageChange(prevState => !prevState);
    });
    return unlisten;
  }, [history]);

  return (
    <Dialog disableEnforceFocus disableScrollLock open={open} onClose={onClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title modal-payment-title">Verifique su pago</h5>
        </div>
        {renderContent(user)}

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary modal-payment-btn"
            onClick={onClose}>
            Cerrar
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalPayment;
