import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormGroup,
  Input,
  Button,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { getBanners } from "../provider/BannerServices";
import LoadingMsgModal from "../components/LoadingMsgModal";
import ModalInfo from "../components/ModalInfo";
import { deleteBanner, deleteBannerImage } from "../provider/BannerServices";
import { deleteBannerSchema } from "../schemas/validators";
import {
  fetchInitUploadSliders,
  fetchMoviesBySliderId,
} from "../provider/SliderServices";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  list: {
    maxHeight: 300,
    overflow: "auto",
  },
  spacingVertical: {
    marginTop: 20,
    marginBottom: 10,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  btnCancel: {
    backgroundColor: "white",
    color: "red",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});
const EditSlider = ({ history }) => {
  const classes = useStyles();
  const initialValues = {
    slider: null,
    titulo: "",
    isXiclo: "false",
    moviesSelected: [],
    subscriptions: [],
    description: "",
  };
  const [reloadFlag, setReloadFlag] = useState(true);
  const [sliders, setSliders] = useState([]);
  const [movies, setMovies] = useState([]);
  const [sliderSubs, setSlidersSubs] = useState([]);
  const [sliderMovies, setSliderMovies] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    open: false,
    message: "",
    onConfirmation: () => {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    isLoading("Cargando tiras...");
    fetchInitUploadSliders()
      .then(res => {
        isNotLoading();
        if (res.code === 200) {
          console.log(res.data);
          setSliders(res.data.sliders);
          setMovies(res.data.movies);
          setSubscriptions(res.data.subscriptions);
          console.log(res.data.subscriptions);
        } else {
          setInfoModal(prevState => ({
            ...prevState,
            open: true,
            message: "Ocurrió un error al cargar las tiras",
            onConfirmation: () => handleCloseInfoModal(),
          }));
        }
      })
      .catch(err => {
        console.log(err);
        isNotLoading();
      });
  }, []);

  const isLoading = (message = "") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      onConfirmation: () => {},
    }));
  };

  const handleChangeSlider = async (id, setFieldValue) => {
    isLoading("Cargando películas...");
    const response = await fetchMoviesBySliderId(id);
    setSliderMovies(response.data.movies);
    setSlidersSubs(response.data.subscription);
    setFieldValue("moviesSelected", response.data.movies);
    setFieldValue("subscriptions", response.data.subscription);
    isNotLoading();
  };

  const onSubmitEditSlider = (values, resetForm) => {
    console.log(values);
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Editar tira"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalInfo
        open={infoModal.open}
        message={infoModal.message}
        onConfirmation={infoModal.onConfirmation}
      />
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={11} lg={9} xl={8}>
          <Paper className={classes.paperContent}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={true}
              validateOnChange={true}
              //   validationSchema={deleteBannerSchema}
              onSubmit={(values, { resetForm, setFieldError }) => {
                onSubmitEditSlider(values);
              }}>
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item xs={12}>
                      <Typography>
                        <strong>Ingrese los nuevos datos de la tira</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        noOptionsText="Sin opciones"
                        options={sliders}
                        getOptionLabel={slider => slider.sl_name}
                        value={values.slider}
                        onChange={(e, value) => {
                          console.log(value);
                          if (value !== null) {
                            handleChangeSlider(value.sl_id, setFieldValue);
                          } else {
                            setSliderMovies([]);
                            setFieldValue(
                              "subscriptions",
                              initialValues.subscriptions,
                            );
                            setFieldValue(
                              "moviesSelected",
                              initialValues.moviesSelected,
                            );
                          }
                          setFieldValue(
                            "slider",
                            value !== null ? value : initialValues.slider,
                          );
                          setFieldValue(
                            "titulo",
                            value !== null
                              ? value.sl_name
                              : initialValues.titulo,
                          );
                          setFieldValue(
                            "titulo",
                            value !== null
                              ? value.sl_name
                              : initialValues.titulo,
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Tira"
                            placeholder="Seleccionar tira"
                            variant="outlined"
                            error={errors.slider ? true : false}
                            helperText={errors.slider && errors.slider}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Titulo"
                        placeholder="Titulo de la tira"
                        name="titulo"
                        onChange={handleChange}
                        value={values.titulo}
                        error={errors.titulo ? true : false}
                        helperText={errors.titulo && errors.titulo}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        error={errors.isXiclo ? true : false}>
                        <FormLabel component="legend">Es xiclo?</FormLabel>
                        <RadioGroup
                          row
                          name="isXiclo"
                          value={values.isXiclo}
                          onChange={handleChange}>
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Si"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {errors.isXiclo && errors.isXiclo}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {10 === 10 && (
                      <Fragment>
                        <Grid item xs={12} md={6}>
                          <Typography>Descripción</Typography>
                          <TextField
                            value={values.description}
                            name="description"
                            onChange={handleChange}
                            placeholder="Descripción de xiclo"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            multiline
                            minRows={5}
                            error={errors.description ? true : false}
                            helperText={
                              errors.description && errors.description
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>Imagen del Banner</Typography>
                          <FormGroup row>
                            <Grid item xs>
                              <TextField
                                value={values.image?.name || ""}
                                InputProps={{
                                  readOnly: true,
                                }}
                                variant="outlined"
                                fullWidth
                                placeholder="Imagen del Banner"
                                error={errors.image ? true : false}
                                helperText={errors.image && errors.image}
                              />
                            </Grid>
                            <Grid item>
                              <label
                                htmlFor="contained-button-file"
                                style={{ height: "100%" }}>
                                <Input
                                  accept="image/png, image/jpg, image/jpeg"
                                  id="contained-button-file"
                                  type="file"
                                  hidden
                                  onChange={e => {
                                    setFieldValue(
                                      "image",
                                      e.target.files[0]
                                        ? e.target.files[0]
                                        : initialValues.image,
                                    );
                                  }}
                                />
                                <Button
                                  className={classes.uploadButton}
                                  component="span"
                                  variant="contained"
                                  disableElevation
                                  style={{ height: "100%" }}>
                                  Subir
                                </Button>
                              </label>
                            </Grid>
                          </FormGroup>
                        </Grid>
                      </Fragment>
                    )}
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        noOptionsText="Sin opciones"
                        options={movies}
                        getOptionLabel={movie => movie.mo_title}
                        getOptionSelected={(option, value) =>
                          option.mo_id === value.mo_id
                        }
                        value={values.moviesSelected}
                        onChange={(e, value) => {
                          setFieldValue("moviesSelected", value);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Seleccione las películas"
                            variant="outlined"
                            error={errors.moviesSelected ? true : false}
                            helperText={
                              errors.moviesSelected && errors.moviesSelected
                            }
                          />
                        )}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 4 }}
                              checked={selected}
                            />
                            {option.mo_title}
                          </React.Fragment>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ marginBottom: "10px" }}>
                        <strong>Películas dentro de la tira</strong>
                      </Typography>
                      <List dense component={Paper} className={classes.list}>
                        {sliderMovies.length > 0 &&
                          sliderMovies.map(movie => (
                            <Fragment key={movie.mo_id}>
                              <ListItem>
                                <ListItemText primary={`${movie.mo_title}`} />
                              </ListItem>
                              <Divider />
                            </Fragment>
                          ))}
                      </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        noOptionsText="Sin opciones"
                        options={subscriptions}
                        getOptionLabel={sub => sub.su_name}
                        getOptionSelected={(option, value) =>
                          option.sl_id === value.sl_id
                        }
                        value={values.subscriptions}
                        onChange={(e, value) => {
                          setFieldValue("subscriptions", value);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Suscripciones"
                            placeholder="Seleccionar una suscripción"
                            variant="outlined"
                            error={errors.subscriptions ? true : false}
                            helperText={
                              errors.subscriptions && errors.subscriptions
                            }
                          />
                        )}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 4 }}
                              checked={selected}
                            />
                            {option.su_name}
                          </React.Fragment>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} />
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="flex-end"
                      className={classes.spacingVertical}>
                      <button
                        type="button"
                        className={`btn btn-default ${classes.btnCancel}`}
                        onClick={() => history.goBack()}>
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-hover">
                        Actualizar tira
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default EditSlider;
