import React, { useEffect, useState } from "react";
import "../css/MovieDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Footer from "../components/Footer";
import Header from "../components/Header";
import SliderPull from "../components/SliderPull";
import Loader from "../components/Loader";
import { getMovie, getSlider } from "../provider/ShowDetailsServices";

import { listAction } from "../actions/MyListAction";
import { getMyList } from "../provider/MyListServices";
import BannerShowDetails from "../components/BannerShowDetails";
import { scrollToTop } from "../common/Util";
import { useQuery } from "../hooks/useQuery";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MovieDetails = ({ freeMode }) => {
  const { state } = useLocation();
  const usr = useSelector(state => state.usr);
  const ml = useSelector(state => state.ml);
  const dispatch = useDispatch();
  let query = useQuery();
  const [loading, setLoading] = useState(true);
  const [loadingSlider, setLoadingSlider] = useState(true);
  const [movie, setMovie] = useState(null);
  const [slider, setSlider] = useState(null);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [query]);

  useEffect(() => {
    let id = query.get("id");
    getMovie(id).then(result => {
      setMovie(result.data);
      setLoading(false);
    });
  }, [query]);

  useEffect(() => {
    if (movie) {
      if (movie.mo_sliders.length > 0) {
        if (slider?.sl_id !== movie?.mo_sliders[0]?.sl_id) {
          getSlider(movie.mo_sliders[0].sl_id).then(result => {
            const sliderFetch = result.data;
            setSlider(() => sliderFetch[0]);
            setLoadingSlider(false);
          });
        }
      } else {
        setLoadingSlider(false);
      }
    }
  }, [movie, slider]);

  // TODO: QUITAR ESTO
  useEffect(() => {
    if (usr.user) {
      getMyList().then(result => {
        if (result.count_movies !== ml.count_movies) {
          dispatch(listAction(result));
        }
      });
    }
  }, [ml.movies, usr.user, ml.count_movies, dispatch]);

  useEffect(function toTop() {
    scrollToTop();
  }, []);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleModal = action => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrió un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrió un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  const renderAwards = () => {
    if (movie.mo_awards) {
      return movie.mo_awards.split("<br />").map((award, index) => {
        return <li key={index}>{award}</li>;
      });
    } else {
      return null;
    }
  };

  return (
    <div id="show-details">
      <Helmet>
        <meta charSet="utf-8" />
        {state ? (
          <title>{state.title ? state.title : "Detalle - Xiclos"}</title>
        ) : (
          <title>
            {query.get("title") ? `${query.get("title")}` : "Detalle - Xiclos"}
          </title>
        )}
        {state ? (
          <meta
            name="description"
            content={
              state.description
                ? state.description
                : "Detalle/información de película"
            }
          />
        ) : (
          <meta
            name="description"
            content={
              query.get("description")
                ? `${query.get("description")}`
                : "Detalle/información de película"
            }
          />
        )}
      </Helmet>
      <Header />
      <div className="movie-details">
        <div>
          <section>
            <div id="show-movie-detail">
              {loading ? (
                <Loader show={true} />
              ) : (
                <>
                  {movie && (
                    <BannerShowDetails
                      movie={movie}
                      handleModal={handleModal}
                      freeMode={freeMode}
                    />
                  )}
                </>
              )}
            </div>
          </section>
        </div>

        {movie && movie.mo_awards ? (
          <div className="movie-awards-section pb-4">
            <div className="movie-awards-title pt-4 pb-4">
              <span className="icon-title"></span>
              <h4>Distinciones</h4>
            </div>

            <div>
              <ul className="movie-awards-list">{renderAwards()}</ul>
            </div>
          </div>
        ) : null}

        {loadingSlider
          ? null
          : slider?.movies &&
            slider?.movies.length > 0 && (
              <SliderPull
                id={"vortex-recommend"}
                title={"También te puede interesar"}
                slider={slider}
                handleModal={handleModal}
              />
            )}
      </div>
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default MovieDetails;
