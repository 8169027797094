import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  descriptionText: {
    fontSize: "0.8em",
    fontFamily: "var(--xiclos-font-helv-bold)",
  },
  descriptionItemText: {
    color: "var(--xiclos-red)",
  },
  sinopsisText: {
    fontSize: "0.7em",
    fontFamily: "var(--xiclos-font-helv-normal)",
  },
  tagText: {
    fontSize: "0.85em",
    fontFamily: "var(--xiclos-font-helv-bold)",
    lineHeight: 1.5,
  },
  tagEdadText: {
    borderRadius: "3px",
    border: "1px solid #fff",
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
});
