import {
  ACT_IS_ADMIN_TRUE,
  ACT_IS_ADMIN_FALSE,
  ACT_IS_SEO_TRUE,
  ACT_IS_SEO_FALSE,
} from "../actions/ActionTypes";

const initialState = {
  isAdmin: false,
  isSeo: false,
};

export default function (state = initialState, action: { type: string }) {
  if (action.type === ACT_IS_ADMIN_TRUE) {
    return { ...state, isAdmin: true };
  }
  if (action.type === ACT_IS_ADMIN_FALSE) {
    return { ...state, isAdmin: false };
  }
  if (action.type === ACT_IS_SEO_TRUE) {
    return { ...state, isSeo: true };
  }
  if (action.type === ACT_IS_SEO_FALSE) {
    return { ...state, isSeo: false };
  }
  return state;
}
