/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Movie } from "../../constants";
import { useStyle } from "./styles";
import PosterCarousel from "../PosterCarousel";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";

export interface CarouselProps {
  initialItems: Movie[];
  fetchMoreItems?: (page: number, id: number) => Promise<Movie[]>;
  sliderId?: number;
  handleModal: (type: string) => any;
}

const SliderCarousel: React.FC<CarouselProps> = ({
  initialItems,
  fetchMoreItems,
  sliderId,
  handleModal,
}) => {
  const classes = useStyle();
  const [Movies, setMovies] = useState<Movie[]>(initialItems);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const splideRef = useRef<Splide>(null);

  useEffect(() => {
    setMovies(initialItems);
  }, [initialItems]);

  const loadMoreItems = async (moviesLength: number) => {
    if (!fetchMoreItems || !sliderId) return;
    if (isLoading) return;

    try {
      setIsLoading(true);
      const newItems = await fetchMoreItems(moviesLength, sliderId);

      if (newItems.length > 0) {
        setMovies(prevItems => [...prevItems, ...newItems]);
      }
    } catch (error) {
      console.error("Error loading more items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMoved = (splide: any) => {
    const lastSlide = splide.length - 1;
    const currentSlide = splide.index;

    if (fetchMoreItems && currentSlide >= lastSlide - splide.options.perPage) {
      loadMoreItems(splide.length);
    }
  };

  return (
    <Splide
      ref={splideRef}
      className={classes.carouselContainer}
      options={{
        speed: 800,
        type: "slide",
        perPage: 6,
        perMove: 5,
        gap: "1.2rem",
        pagination: false,
        arrows: true,
        padding: { left: "1rem", right: "2rem" },
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
        reducedMotion: {
          speed: 800,
          rewindSpeed: 800,
        },
        breakpoints: {
          320: {
            perPage: 2,
            perMove: 1,
            arrows: false,
            padding: { left: "0.3rem", right: "0.8rem" },
            gap: "0.5rem",
          },
          599: {
            perPage: 3,
            perMove: 2,
            arrows: false,
            padding: { left: "0.3rem", right: "0.8rem" },
            gap: "0.5rem",
          },
          899: {
            perPage: 4,
            perMove: 3,
          },
          1199: {
            perPage: 6,
            perMove: 5,
          },
          1535: {
            perPage: 7,
            perMove: 6,
          },
          3200: {
            perPage: 8,
            perMove: 7,
          },
        },
      }}
      onMoved={handleMoved}>
      {Movies.map(movie => (
        <SplideSlide key={movie.mo_id}>
          <PosterCarousel movie={movie} handleModal={handleModal} />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default SliderCarousel;
