import React from "react";
import "../css/cine-club.scss";

const DirectorHeader = ({ movie }) => {
  return (
    <div
      className="row xiclos-row-red cine-club"
      style={{ marginBottom: 0, marginTop: 0 }}>
      <div className="row xiclos-row-red-elements-position">
        <img
          className="rounded-circle xiclos-image-profile"
          src={movie.sl_img}
          alt=""
        />
        <div className="cine-club-title d-sm-block">
          <h4 style={{ paddingLeft: "6px" }}>{movie.sl_name}</h4>
        </div>
      </div>
    </div>
  );
};

export default DirectorHeader;
