import { Grid, Paper, Typography, Link } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import XiclosYellow from "../images/icons/xiclos-icon-yellow@3x.png";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";

import { makeStyles } from "@mui/styles";
import { scrollToTop } from "../common/Util";
import NavigationTitle from "../components/NavigationTitle";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
  ul: {
    "& li": {
      padding: "10px 0",
    },
  },
  paperContent: {
    padding: "20px",
  },
  paperContainer: {
    padding: "10px",
    backgroundImage:
      "linear-gradient(to right,rgb(26, 26, 26) 0%,rgb(52, 52, 51) 49%,rgb(26, 26, 26) 100%)",
    paddingBottom: "50px",
  },
}));

const WhoWeAre = () => {
  const classes = useStyles();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quienes somos - Xiclos</title>
        <meta name="description" content="Página informativa de xiclos y la aplicación" />
      </Helmet>
      <Header />
      <NavigationTitle title={"Quienes somos"} icon={XiclosYellow} />
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={10} lg={8}>
          <Paper className={classes.paperContent}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <ul className={classes.ul}>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos, ante todo, amantes del buen cine{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos, también, especialistas con una amplia trayectoria
                      en el rubro audiovisual{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos exigentes a la hora de seleccionar qué ver{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos conservadores y nos encantan los clásicos{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos vanguardistas y exploramos las novedades{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos curiosos y buscamos títulos de todo el mundo{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos buenos consejeros y te ofrecemos la mejor selección{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos muy sociables y queremos compartir con vos la magia
                      de este “gran” arte{" "}
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      style={{
                        color: "var(--xiclos-bg)",
                        fontFamily: "var(--xiclos-font-helv-normal",
                      }}>
                      Somos XICLOS y llevamos el mejor cine a tu casa{" "}
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "left",
                      padding: 20,
                      background: "rgba(0,0,0,.01)",
                      borderRadius: 5,
                      boxShadow: "0 5px 10px rgba(0,0,0,.05)",
                    }}>
                    <Typography
                      style={{
                        fontFamily: "var(--xiclos-font-helv-bold",
                        fontSize: "1.5em",
                      }}
                      color="primary">
                      CONTACTO
                    </Typography>
                    <div style={{ margin: 5 }}>
                      <PlaceIcon color="primary" />{" "}
                      <Typography variant="button">Buenos Aires</Typography>
                    </div>
                    <div style={{ margin: 5 }}>
                      <EmailIcon color="primary" />{" "}
                      <Link
                        variant="button"
                        color="inherit"
                        href={`mailto:info@xiclos.com`}>
                        INFO@XICLOS.COM
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default WhoWeAre;
