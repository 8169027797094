import React from "react";
import { Menu, MenuItem, MenuList } from "@mui/material";

const MenuPanelControlUsers = ({
  actionMembresia,
  actionRol,
  anchorEl,
  onClose,
  actionChangeStateUser,
  user,
  actionAlterSub,
}) => {
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}>
      <MenuList>
        <MenuItem onClick={actionMembresia}>Modificar membresía</MenuItem>
        <MenuItem onClick={actionRol}>Modificar rol</MenuItem>
        {user.us_suscription_status === "free" ? (
          <MenuItem onClick={() => actionChangeStateUser()}>
            Convertir a usuario activo
          </MenuItem>
        ) : (
          <MenuItem onClick={() => actionChangeStateUser()}>
            Convertir a usuario free
          </MenuItem>
        )}
        {user.us_suscription_status === "active" ||
        user.us_suscription_status === "pending" ? (
          <MenuItem onClick={() => actionAlterSub("pause")}>
            Pausar suscripción
          </MenuItem>
        ) : (
          user.us_suscription_status === "inactive" && (
            <MenuItem onClick={() => actionAlterSub("activate")}>
              Activar suscripción
            </MenuItem>
          )
        )}
      </MenuList>
    </Menu>
  );
};

export default MenuPanelControlUsers;
