/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { createFreeUserSchema } from "../schemas/validators";
import { createFreeUser } from "../provider/UserService";
import ModalMui from "../components/ModalMui";
import LoadingMsgModal from "../components/LoadingMsgModal";
import { API } from "../provider/APIConnector";
import moment from "moment";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  radio: {
    margin: 0,
  },
  spacingVertical: {
    marginTop: 20,
    marginBottom: 10,
  },
  spacingHorizontal: {
    marginLeft: 10,
    marginRight: 10,
  },
  modalInfoBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  btnCancel: {
    backgroundColor: "white",
    color: "red",
  },
});

const UploadUserFree = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialValues: {
    name: string;
    lastname: string;
    email: string;
    zipcode: string;
    city: string;
    state: string;
    country: string;
    subscription: string;
    expDate: string | null;
  } = {
    name: "",
    lastname: "",
    email: "",
    zipcode: "",
    city: "",
    state: "",
    country: "",
    subscription: "",
    expDate: moment(new Date()).format("YYYY-MM-DD"),
  };
  const [provincias, setProvincias] = useState([]);
  const [cities, setCities] = useState<any>([]);
  const [expDateExist, setExpDateExist] = useState("false");
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    message: "",
    open: false,
    callBack: () => {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(API + "/ar/provinces")
      .then(res => res.json())
      .then(response => setProvincias(response.data));
  }, []);

  const handleChangeExpDate = (e: any) => {
    if (e.target.value === "true") {
      setExpDateExist("true");
    } else if (e.target.value === "false") {
      setExpDateExist("false");
    }
  };

  const onStateChange = async (id: any, setFieldValue: any) => {
    setFieldValue("city", "");
    const response = await fetch(API + `/ar/${id}/cities`);
    const data = await response.json();
    if (id === "02") {
      setCities([data.data[1]]);
    } else {
      setCities(data.data);
    }
  };

  const onSubmit = async (values: any, resetForm: any) => {
    isLoading("Creando usuario...");
    try {
      const response = await createFreeUser(values);
      if (response.code === 200) {
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Usuario creado con éxito!",
          callBack: () => {
            resetForm();
            setExpDateExist("false");
            handleCloseInfoModal();
          },
        }));
      } else {
        isNotLoading();
        if (
          response.errorMessage &&
          response.errorMessage === "Los campos son inválidos"
        ) {
          setInfoModal(prevState => ({
            ...prevState,
            open: true,
            message: "Existen campos inválidos, revise los datos ingresados",
            callBack: () => {
              handleCloseInfoModal();
            },
          }));
        } else if (
          response.errorMessage &&
          response.errorMessage === "El usuario ya existe con ese correo"
        ) {
          setInfoModal(prevState => ({
            ...prevState,
            open: true,
            message: "El usuario ya existe con ese correo",
            callBack: () => {
              handleCloseInfoModal();
            },
          }));
        } else {
          setInfoModal(prevState => ({
            ...prevState,
            open: true,
            message: "Ocurrió un error al crear el usuario",
            callBack: () => {
              handleCloseInfoModal();
            },
          }));
        }
      }
    } catch (err) {
      console.log(err);
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Ocurrió un error al crear el usuario",
        callBack: () => {
          handleCloseInfoModal();
        },
      }));
    }
  };

  const isLoading = (message = "") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      callBack: () => {},
    }));
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Agregar usuario FREE"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
        descriptionText={undefined}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalMui
        open={infoModal.open}
        handleClose={() => {}}
        maxWidth="xs"
        actions={undefined}
        title={undefined}
        close={undefined}
        fullWidth={undefined}>
        <Box className={classes.modalInfoBox}>
          <Typography variant="h6" style={{ color: "black" }}>
            {infoModal.message}
          </Typography>
        </Box>
        <Box className={classes.modalInfoBox}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={infoModal.callBack}>
            Aceptar
          </Button>
        </Box>
      </ModalMui>
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={10} lg={8} xl={8}>
          <Paper className={classes.paperContent}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={formIsDirty}
              validateOnChange={formIsDirty}
              validationSchema={createFreeUserSchema}
              onSubmit={(values, { resetForm }) => {
                if (expDateExist === "false") {
                  values = {
                    ...values,
                    expDate: null,
                  };
                }
                onSubmit(values, resetForm);
              }}>
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form
                  onSubmit={e => {
                    setFormIsDirty(true);
                    handleSubmit(e);
                  }}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography>Nombre</Typography>
                      <TextField
                        placeholder="Nombre"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Apellido</Typography>
                      <TextField
                        placeholder="Apellido"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange}
                        error={errors.lastname ? true : false}
                        helperText={errors.lastname && errors.lastname}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Email</Typography>
                      <TextField
                        placeholder="Email"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email ? true : false}
                        helperText={errors.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>País</Typography>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={errors.country ? true : false}>
                        <Select
                          fullWidth
                          value={values.country}
                          name="country"
                          onChange={e => {
                            handleChange(e);
                            setFieldValue("state", "");
                            setFieldValue("city", "");
                          }}>
                          <MenuItem value="" disabled selected>
                            <em>País</em>
                          </MenuItem>
                          <MenuItem value={"Argentina"}>Argentina</MenuItem>
                          <MenuItem value={"Perú"}>Perú</MenuItem>
                          <MenuItem value={"Paraguay"}>Paraguay</MenuItem>
                          <MenuItem value={"Chile"}>Chile</MenuItem>
                          <MenuItem value={"Uruguay"}>Uruguay</MenuItem>
                          <MenuItem value={"Otro"}>Otro</MenuItem>
                        </Select>
                        {errors.country && (
                          <FormHelperText>{errors.country}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {values.country !== "" ? (
                      values.country === "Argentina" ? (
                        <Fragment>
                          <Grid item xs={12}>
                            <Typography>Provincia</Typography>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              error={errors.state ? true : false}>
                              <Select
                                fullWidth
                                value={values.state}
                                name="state"
                                onChange={handleChange}>
                                <MenuItem value="" disabled selected>
                                  <em>Provincia</em>
                                </MenuItem>
                                {provincias.map((provincia: any) => (
                                  <MenuItem
                                    key={provincia.id}
                                    onClick={() =>
                                      onStateChange(provincia.id, setFieldValue)
                                    }
                                    value={`${provincia.nombre}`}>
                                    {provincia.nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.state && (
                                <FormHelperText>{errors.state}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {cities.length > 0 && (
                            <Grid item xs={12}>
                              <Typography>Ciudad</Typography>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                error={errors.city ? true : false}>
                                <Select
                                  fullWidth
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}>
                                  <MenuItem value="" disabled selected>
                                    <em>Ciudad</em>
                                  </MenuItem>
                                  {cities.map((city: any) => (
                                    <MenuItem
                                      key={city.id}
                                      value={`${city.nombre}`}>
                                      {city.nombre}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.city && (
                                  <FormHelperText>{errors.city}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Grid item xs={12}>
                            <Typography>Región/Provincia</Typography>
                            <TextField
                              placeholder="Región/Provincia"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                              error={errors.state ? true : false}
                              helperText={errors.state && errors.state}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>Ciudad/Localidad</Typography>
                            <TextField
                              placeholder="Ciudad/Localidad"
                              variant="outlined"
                              color="primary"
                              fullWidth
                              name="city"
                              value={values.city}
                              onChange={handleChange}
                              error={errors.city ? true : false}
                              helperText={errors.city && errors.city}
                            />
                          </Grid>
                        </Fragment>
                      )
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <Typography>Suscripciones</Typography>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={errors.subscription ? true : false}>
                        <Select
                          fullWidth
                          value={values.subscription}
                          name="subscription"
                          onChange={handleChange}>
                          <MenuItem value="" disabled selected>
                            <em>Suscripciones</em>
                          </MenuItem>
                          <MenuItem value={"GENERAL"}>
                            {"General (free)"}
                          </MenuItem>
                          <MenuItem value={"EXCLUSIVOALUMNOS01"}>
                            {"Exclusivo (free)"}
                          </MenuItem>
                        </Select>
                        {errors.subscription && (
                          <FormHelperText>{errors.subscription}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Código Postal</Typography>
                      <TextField
                        placeholder="Código Postal"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="zipcode"
                        value={values.zipcode}
                        onChange={handleChange}
                        error={errors.zipcode ? true : false}
                        helperText={errors.zipcode && errors.zipcode}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>
                          Desea añadirle una fecha de expiración?
                        </FormLabel>
                        <RadioGroup
                          value={expDateExist}
                          onChange={handleChangeExpDate}
                          row>
                          <FormControlLabel
                            value={"true"}
                            control={<Radio />}
                            label="Si"
                          />
                          <FormControlLabel
                            value={"false"}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {expDateExist === "true" && (
                      <Grid item xs={4}>
                        <TextField
                          label="Expiración del usuario FREE"
                          type="date"
                          variant="outlined"
                          fullWidth
                          onChange={handleChange("expDate")}
                          value={values.expDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      container
                      xs
                      justifyContent="flex-end"
                      className={classes.spacingVertical}>
                      <button
                        type="button"
                        className={`btn btn-default ${classes.btnCancel}`}
                        onClick={() => history.goBack()}>
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-hover">
                        Crear
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default UploadUserFree;
