let mercadoPagoInstance = null;

export const getMercadoPagoInstance = async () => {
  const mercadoPagoConfig = {
    public_key: process.env.REACT_APP_MP_PUBLIC_KEY,
    options: { locale: "es-AR" },
  };
  const script = document.createElement("script");

  script.src = "https://sdk.mercadopago.com/js/v2";
  script.async = true;

  document.body.appendChild(script);
  let promise = new Promise(function (resolve, reject) {
    script.onload = () => {
      mercadoPagoInstance = new window.MercadoPago(
        mercadoPagoConfig.public_key,
        mercadoPagoConfig.options
      );
      resolve(mercadoPagoInstance);
    };
  });
  return promise;
};
