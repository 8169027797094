import { ACT_CINECLUB_FETCH_MOVIES } from "./ActionTypes";

import { getAllSliders } from "../provider/SliderServices";

export function fetchCineClubSliders({ videoclub, cbSuccess, cbFail }) {
  return (dispatch) => {
    getAllSliders(0, 100, { videoclub: 1 })
      .then((res) => {
        if (res.code === 200) {
          dispatch({
            type: ACT_CINECLUB_FETCH_MOVIES,
            payload: res.data,
          });
          cbSuccess && cbSuccess();
        } else {
          cbFail && cbFail();
        }
      })
      .catch((err) => {
        cbFail && cbFail();
      });
  };
}
