import axios from "axios";
import { API } from "../APIConnector";

const axiosInit = axios.create({
  baseURL: API,
  timeout: 15000,
  headers: {
    Accept: "application/json",
  },
});

export default axiosInit;
