import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  descriptionText: {
    fontSize: "0.8em",
    fontFamily: "var(--xiclos-font-helv-bold)",
  },
  descriptionItemText: {
    color: "var(--xiclos-red)",
  },
  sinopsisText: {
    fontSize: "0.7em",
    fontFamily: "var(--xiclos-font-helv-normal)",
  },
  tagText: {
    fontSize: "0.85em",
    fontFamily: "var(--xiclos-font-helv-bold)",
    lineHeight: 1.5,
  },
  tagEdadText: {
    borderRadius: "3px",
    border: "1px solid #fff",
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    alignItems: "center",
  },
  buttonWatchMovie: {
    cursor: "pointer",
    border: "none",
    fontFamily: "var(--xiclos-font-helv-medium)",
    fontSize: "0.9em",
    width: "100%",
    borderRadius: 6,
    padding: "8px 20px",
    background: "var(--iq-primary)",
    color: "var(--iq-white)",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    transition: "color 0.2s ease",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: "100%",
      bottom: 0,
      background: "var(--iq-primary-hover)",
      zIndex: -1,
      transition: "0.2s ease-out",
    },

    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "100%",
      right: 0,
      bottom: 0,
      background: "var(--iq-primary-hover)",
      zIndex: -1,
      transition: "0.2s ease-out",
    },

    "&:hover": {
      color: "var(--iq-white)",
      fontWeight: "400",

      "&::before": {
        right: "50%",
      },

      "&::after": {
        left: "50%",
      },
    },
  },
  buttonWatchDetail: {
    background: "none",
    border: "none",
    color: "#fff",
    cursor: "pointer",
    fontSize: "0.9em",
    padding: 0,
    width: "100%",
    fontFamily: "var(--xiclos-font-helv-bold)",
    "&:hover": {
      opacity: 0.8,
    },
  },
  bottomInfoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    alignItems: "center",
  },
  imageContainer: {
    aspectRatio: "9/14",
    borderRadius: 5,
  },
  image: {
    width: 140,
    height: 220,
    objectFit: "cover",
    borderRadius: 5,
    transition: "all 0.3s ease",
    position: "relative",
  },
  titleText: {
    fontFamily: "var(--xiclos-font-helv-bold)",
    fontWeight: 500,
    fontSize: "1.1em",
    textTransform: "uppercase",
    lineHeight: 1.2,
    color: "var(--iq-white)",
  },
  loader: { width: 140, height: 220 },
  infoContainer: {
    paddingLeft: 10,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    columnGap: 10,
    gap: 10,
  },
  buttonFavorite: {
    background: "none",
    border: "1px solid #fff",
    borderRadius: 6,
    color: "#fff",
    cursor: "pointer",
    fontFamily: "var(--xiclos-font-abel)",
    fontSize: "1em",
    fontWeight: 600,
    padding: "5px 10px"
  },
});
