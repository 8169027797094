import React, { useEffect, useState, Fragment } from "react";
import { API } from "../provider/APIConnector";

import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import Footer from "../components/Footer";
import PosterPull from "../components/PosterPull";
import Loader from "../components/Loader";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { useDispatch, useSelector } from "react-redux";
import { getMyList } from "../provider/MyListServices";
import { listAction } from "../actions/MyListAction";
import { useQuery } from "../hooks/useQuery";
import ScrollButton from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const ResultSearch = () => {
  const query = useQuery();
  const ml = useSelector(state => state.ml);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [movies, setMovies] = useState([]);
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (ml.count_movies === 0) {
      getMyList()
        .then(result => {
          if (result.count_movies !== ml.count_movies) {
            dispatch(listAction(result));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [dispatch, ml.count_movies]);

  useEffect(() => {
    setLoading(true);
    setMovies([]);
    const searchWord = query.get("search");
    fetch(API + "/search?str=" + searchWord, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("Authorization"),
        U: localStorage.getItem("U"),
      },
    })
      .then(res => res.json())
      .then(data => {
        if (!data.data.message) {
          setLoading(false);
          setMovies(data.data);
        } else {
          setLoading(false);
        }
      });
  }, [query]);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleModal = action => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrió un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrió un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  return (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buscar - Xiclos</title>
        <meta name="description" content="Búsqueda de películas" />
      </Helmet>
      <Header />
      <NavigationTitle
        title={`Películas encontradas para: ${query.get("search") || "-"}`}
        icon={"../images/icons/xiclos-icon-yellow@2x.png"}
      />
      <div className="wrapper-movie-interest col-12 d-flex flex-wrap vortex-slides vortex-slides-pull px-md-5 mx-auto pt-4 pb-4">
        {movies.length > 0 ? (
          movies.map(movie => (
            <Fragment key={movie.mo_id}>
              <PosterPull
                id={movie.mo_id}
                item={movie}
                handleModal={handleModal}
              />
            </Fragment>
          ))
        ) : loading ? (
          <div className="divLoading">
            <Loader show={loading} />
          </div>
        ) : (
          <Fragment>
            <p
              className="w-100 text-center"
              style={{
                padding: "15vh 0px 15vh 0px  ",
                color: "white",
                fontSize: "large",
              }}>
              No se encontró ninguna película en la búsqueda
            </p>
          </Fragment>
        )}
      </div>
      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default ResultSearch;
