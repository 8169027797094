/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import NavigationTitle from "../../components/NavigationTitle";
import Footer from "../../components/Footer";

import { useSelector, useDispatch } from "react-redux";
import { getMyList } from "../../provider/MyListServices";
import { listAction } from "../../actions/MyListAction";
import { CircularProgress, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ScrollButton from "../../components/ScrollToTop";
import { Helmet } from "react-helmet";
import { useStyle } from "./styles";
import Poster from "./components/Poster";
import { Movie } from "./constants";
import { useMediaQuery } from "react-responsive";
import { BREAKPOINTS } from "../../common/constant";

const MyList = () => {
  const classes = useStyle();
  const isDesktop = useMediaQuery(BREAKPOINTS.desktop);

  const myList = useSelector((state: any) => state.ml);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getMyList().then(result => {
      setLoading(false);
      if (result.count_movies !== myList.count_movies) {
        dispatch(listAction(result));
      }
    });
  }, [dispatch, myList.count_movies]);

  const handleCloseSnackBar = () => {
    setSnackState({ ...snackState, open: false });
  };

  const handleModal = (action: string) => {
    switch (action) {
      case "add":
        setSnackState({
          message: "Agregaste la película a tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "remove":
        setSnackState({
          message: "Borraste la película de tu lista!",
          open: true,
          type: "success",
        });
        break;
      case "errorRemove":
        setSnackState({
          message: "Ocurrió un error al eliminar de la lista",
          open: true,
          type: "error",
        });
        break;
      case "errorAdd":
        setSnackState({
          message: "Ocurrió un error al añadir a la lista",
          open: true,
          type: "error",
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mi lista - Xiclos</title>
        <meta name="description" content="Lista de favoritos del usuario" />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Mi lista"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
        descriptionText={""}
      />

      {loading ? (
        <div className={classes.loading}>
          <CircularProgress size={82} />
        </div>
      ) : (
        <div
          className={classes.container}
          style={isDesktop ? { paddingInline: 100 } : { paddingInline: 20 }}>
          {myList.movies.map((movie: Movie) => (
            <Poster key={movie.mo_id} movie={movie} handleModal={handleModal} />
          ))}
        </div>
      )}

      <ScrollButton />
      <Footer />
      <Snackbar
        open={snackState.open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackState.type as any}>
          {snackState.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default MyList;
