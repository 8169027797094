import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Formik } from "formik";
import { editUserSchema } from "../schemas/validators";
import { verifyUserSubscription } from "../provider/SubscriptionService";
import moment from "moment";

const ChangeStateUserContent = ({
  user,
  onClose,
  onSubmit,
  setInfoModal,
  setLoadingModal,
}) => {
  const [isFree, setIsFree] = useState(null);
  const [expDateExist, setExpDateExist] = useState("false");

  useEffect(() => {
    setIsFree(null);
    if (user.us_suscription_status === "free") {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
  }, [user.us_suscription_status]);

  const verifyNewSub = (sub, method, setFieldValue, setFieldError) => {
    if (sub.length === 0) return;
    if (method === "MercadoPago") {
      if (sub.length <= 32) {
        return;
      } else {
        const id = sub.slice(
          sub.indexOf("subscriptor-details?id=") + 23,
          sub.length,
        );
        if (id.length === 32) {
          setFieldValue("newSub.id", id);
        } else {
          setFieldError("newSub.id", "Link o ID no valido");
        }
      }
    } else if (method === "Paypal") {
      if (sub.length <= 14) {
        return;
      } else {
        const id = sub.slice(sub.indexOf("subscriptions/") + 14, sub.length);
        if (id.length === 14) {
          setFieldValue("newSub.id", id);
        } else {
          setFieldError("newSub.id", "Link o ID no valido");
        }
      }
    }
  };

  const verifySub = async (id, setFieldValue) => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message: "Verificando...",
    }));
    try {
      const response = await verifyUserSubscription({ id });
      if (response.data?.subExist === false) {
        setInfoModal(prevState => ({
          open: true,
          message:
            "El usuario no posee una suscripción vinculada, si prosigue el mismo pasara de FREE a INACTIVO",
        }));
        setFieldValue("isVerifiedMethod", true);
      } else if (response.data?.subExist === true) {
        if (
          response.data?.subStatus === "paused" ||
          response.data?.subStatus === "SUSPENDED"
        ) {
          setInfoModal(prevState => ({
            open: true,
            message:
              "El usuario posee una suscripción pausada, si prosigue la suscripción anterior se reanudara y el usuario pasara de FREE a ACTIVO",
          }));
          setFieldValue("isVerifiedMethod", true);
        } else {
          setInfoModal(prevState => ({
            open: true,
            message:
              "El usuario posee una suscripción cancelada, si prosigue el mismo pasara de FREE a INACTIVO",
          }));
          setFieldValue("isVerifiedMethod", true);
        }
      }
      setLoadingModal(prevState => ({
        ...prevState,
        open: false,
        message: "",
      }));
    } catch (err) {
      console.log(err);
      setLoadingModal(prevState => ({
        ...prevState,
        open: false,
        message: "",
      }));
    }
  };

  const handleChangeExpDate = e => {
    if (e.target.value === "true") {
      setExpDateExist("true");
    } else if (e.target.value === "false") {
      setExpDateExist("false");
    }
  };

  return (
    <Formik
      initialValues={{
        newSub: {
          id: "",
          method: "MercadoPago",
        },
        state: isFree,
        expDate: moment(new Date()).format("YYYY-MM-DD"),
        newSubExist: "false",
        isVerifiedMethod: false,
      }}
      //   validationSchema={editUserSchema}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        let previousValues = values;
        if (isFree) {
          if (values.newSubExist === "false") {
            values = {
              id: user.us_id,
              state: "free",
            };
          } else {
            values = {
              id: user.us_id,
              newSub: values.newSub,
              state: "free",
            };
          }
        } else if (isFree === false) {
          if (expDateExist === "true") {
            values = {
              id: user.us_id,
              state: "active",
              expDate: values.expDate,
            };
          } else {
            values = {
              id: user.us_id,
              state: "active",
            };
          }
        }
        if (isFree && previousValues.newSubExist === "true") {
          if (
            previousValues.newSub.id.length === 32 ||
            previousValues.newSub.id.length === 14
          ) {
            onSubmit(values, setSubmitting);
          } else {
            setFieldError("newSub.id", "Link o ID invalido");
            setSubmitting(false);
          }
        } else {
          onSubmit(values, setSubmitting);
        }
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            spellCheck={false}
            noValidate
            autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Nombre y apellido"
                  value={`${user.us_name} ${user.us_lastname}`}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  value={user.us_email}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider variant="fullWidth" />
              </Grid>
              {isFree === null ? (
                <div>NULL</div>
              ) : isFree ? (
                <Fragment>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>
                        Desea vincular una nueva suscripción?
                      </FormLabel>
                      <RadioGroup
                        value={values.newSubExist}
                        onChange={handleChange("newSubExist")}
                        row>
                        <FormControlLabel
                          value={"true"}
                          control={<Radio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value={"false"}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {values.newSubExist === "true" ? (
                    <Fragment>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>
                            Seleccione el tipo de suscripción
                          </FormLabel>
                          <RadioGroup
                            value={values.newSub.method}
                            onChange={handleChange("newSub.method")}
                            row>
                            <FormControlLabel
                              value={"MercadoPago"}
                              control={<Radio />}
                              label="MercadoPago"
                            />
                            <FormControlLabel
                              value={"Paypal"}
                              control={<Radio />}
                              label="Paypal"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {values.newSub.method === "MercadoPago" ? (
                        <Grid item xs={12}>
                          <Typography>
                            Link o ID de suscripción de MercadoPago
                          </Typography>
                          <TextField
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onBlur={() =>
                              verifyNewSub(
                                values.newSub.id,
                                values.newSub.method,
                                setFieldValue,
                                setFieldError,
                              )
                            }
                            value={values.newSub?.id}
                            onChange={handleChange("newSub.id")}
                            error={errors.newSub?.id ? true : false}
                            helperText={errors.newSub?.id && errors.newSub?.id}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Typography>
                            Link o ID de suscripción de Paypal
                          </Typography>
                          <TextField
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onBlur={() =>
                              verifyNewSub(
                                values.newSub.id,
                                values.newSub.method,
                                setFieldValue,
                                setFieldError,
                              )
                            }
                            value={values.newSub?.id}
                            onChange={handleChange("newSub.id")}
                            error={errors.newSub?.id ? true : false}
                            helperText={errors.newSub?.id && errors.newSub?.id}
                          />
                        </Grid>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item xs={12}>
                        <Typography>
                          Primero debe verificar si el usuario posee una
                          suscripción anterior y el estado en el que se
                          encuentra la misma.
                        </Typography>
                        <Button
                          disableElevation
                          disabled={values.isVerifiedMethod}
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 15 }}
                          onClick={() => {
                            verifySub(user.us_id, setFieldValue);
                          }}>
                          Verificar suscripción previa
                        </Button>
                      </Grid>
                    </Fragment>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10, marginBottom: 10 }}>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        disableElevation
                        color="primary"
                        onClick={onClose}>
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disableElevation
                        disabled={
                          isSubmitting ||
                          (!values.isVerifiedMethod &&
                            values.newSubExist === "false")
                        }
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        type="submit">
                        Modificar
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel>
                        Desea añadirle una fecha de expiración?
                      </FormLabel>
                      <RadioGroup
                        value={expDateExist}
                        onChange={handleChangeExpDate}
                        row>
                        <FormControlLabel
                          value={"true"}
                          control={<Radio />}
                          label="Si"
                        />
                        <FormControlLabel
                          value={"false"}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {expDateExist === "true" && (
                    <Grid item xs={6}>
                      <TextField
                        label="Expiración del usuario FREE"
                        type="date"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange("expDate")}
                        value={values.expDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10, marginBottom: 10 }}>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        disableElevation
                        color="primary"
                        onClick={onClose}>
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        type="submit">
                        Modificar
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangeStateUserContent;
