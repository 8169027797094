/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from "react";
import useWidth from "../../../hooks/useWidth";
import { ASPECT_RATIO_POSTER } from "../../../common/constant";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  addMovieListAction,
  removeMovieListAction,
} from "../../../actions/MyListAction";
import PosterDetail from "../PosterDetail";
import { useHistory } from "react-router-dom";

interface PosterProps {
  movie: any;
  handleModal: (...args: any) => any;
  noLista?: boolean;
  freeMode?: boolean;
}

const Poster = ({ movie, handleModal, noLista, freeMode }: PosterProps) => {
  const width = useWidth();
  const history = useHistory();
  const dispatch = useDispatch();
  const ml = useSelector((state: any) => state.ml);
  const usr = useSelector((state: any) => state.usr);
  const [loading, setLoading] = useState(true);
  const [onHover, setOnHover] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const posterRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width === "sm" || width === "xs") {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (onHover && posterRef.current) {
      const poster = posterRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Asumiendo que PosterDetail tiene un ancho de 300px y alto de 400px
      const tooltipWidth = 500;
      const tooltipHeight = 350;

      let left = poster.right - 100; // 10px de margen
      let top = poster.top;

      // Si el tooltip se sale por la derecha
      if (left + tooltipWidth > viewportWidth) {
        left = poster.left - tooltipWidth + 100;
      }

      // Si el tooltip se sale por abajo
      if (top + tooltipHeight > viewportHeight) {
        top = viewportHeight - tooltipHeight + 100;
      }

      // Si el tooltip se sale por arriba
      if (top < 0) {
        top = 10;
      }

      setTooltipPosition({ top, left });
    }
  }, [onHover]);

  const isInMyList = (id: number) => {
    const isInList = ml.movies.filter((mov: any) => {
      if (mov.mo_id === id) {
        return mov;
      } else {
        return false;
      }
    });
    if (isInList.length === 0) {
      return false;
    }
    return true;
  };

  const onAddFavorite = (mov: any) => {
    dispatch(
      addMovieListAction(
        mov,
        () => handleModal("add"),
        () => handleModal("errorAdd"),
      ),
    );
  };

  const onRemoveFavorite = (mov: any) => {
    dispatch(
      removeMovieListAction(
        mov,
        () => handleModal("remove"),
        () => handleModal("errorRemove"),
      ),
    );
  };

  const onNavigateToDetail = (movie: any) => {
    if (freeMode) {
      history.push({
        pathname: "/details-festival",
        search: `?id=${movie.mo_id}`,
        state: {
          title: movie.mo_title_metadata
            ? movie?.mo_title_metadata?.slice(0, 59)
            : movie?.mo_title?.slice(0, 59),
          description: movie.mo_description_metadata
            ? movie?.mo_description_metadata?.slice(0, 159)
            : movie?.mo_description?.slice(0, 159),
        },
      });
    } else {
      history.push({
        pathname: "/details",
        search: `?id=${movie.mo_id}`,
        state: {
          title: movie.mo_title_metadata
            ? movie?.mo_title_metadata?.slice(0, 59)
            : movie?.mo_title?.slice(0, 59),
          description: movie.mo_description_metadata
            ? movie?.mo_description_metadata?.slice(0, 159)
            : movie?.mo_description?.slice(0, 159),
        },
      });
    }
  };

  return (
    <>
      <div
        onClick={() => {
          if (isMobile) {
            onNavigateToDetail(movie);
          }
        }}
        ref={posterRef}
        style={{
          width: "100%",
          aspectRatio: ASPECT_RATIO_POSTER,
          borderRadius: 5,
          transition: "all 0.3s ease",
          position: "relative",
          overflow: "hidden",
          ...(onHover
            ? {
                backgroundColor: "rgba(26, 26, 26, 0.9)",
                transform: "scale(1.05)",
                border: "3px solid black",
                boxShadow: "0 0 10px rgba(0,0,0,0.5)",
              }
            : {}),
        }}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}>
        {movie && movie.mo_thumbnail && (
          <>
            <img
              style={{
                display: loading ? "none" : "block",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 5,
                transition: "all 0.3s ease",
                position: "relative",
              }}
              src={
                width !== "xs"
                  ? movie.mo_thumbnail.replace("_150x260", "")
                  : movie.mo_thumbnail
              }
              alt={movie.mo_title}
              onLoad={() => setLoading(false)}
            />
            {!loading && onHover && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,

                  borderRadius: 5,
                  transition: "all 0.3s ease",
                  ...(isMobile
                    ? {}
                    : {
                        background:
                          "linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%)",
                      }),
                }}
              />
            )}
          </>
        )}
        <Loader show={loading} style={{ aspectRatio: ASPECT_RATIO_POSTER }} />
      </div>

      {!loading && !isMobile && onHover && (
        <div
          ref={modalRef}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          style={{
            position: "fixed",
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            zIndex: 99999,
            opacity: 0,
            transform: "translateX(-20px)",
            animation: "slideIn 0.3s ease forwards",
          }}>
          <style>
            {`
              @keyframes slideIn {
                to {
                  opacity: 1;
                  transform: translateX(0);
                }
              }
            `}
          </style>
          <PosterDetail
            movie={movie}
            usr={usr}
            freeMode={freeMode}
            noLista={noLista}
            handleFavorite={isInMyList}
            onAddFavorite={onAddFavorite}
            onRemoveFavorite={onRemoveFavorite}
          />
        </div>
      )}
    </>
  );
};

export default Poster;
