import React from "react";
import Modal from "@mui/material/Modal";
import { useLoaderApp } from "../hooks/LoaderHooks";
import Logo from "../images/logo.png";

export const LoaderApp = props => {
  // const [openAppLoader, setOpenAppLoader] = useLoaderApp()
  // const onClose = ()=>{
  //   setOpenAppLoader(false)
  // }
  return (
    <Modal
      open={props.open}
      // onClose={onClose}
    >
      <div id="loading">
        <img src={Logo} alt="Xiclos" />
        <i className="ri-loader-4-line"></i>
      </div>
    </Modal>
  );
};

const Loader = props => {
  const { show, style } = props;
  if (show) {
    return (
      <div className="loading-content" style={{ ...style }}>
        <i className="ri-loader-4-line"></i>
      </div>
    );
  }
  return null;
};

export default Loader;
