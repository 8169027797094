/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Movie } from "../../constants";
import { useStyle } from "./styles";
import Loader from "../../../../components/V2/Loader";
import { Popper, Grow } from "@mui/material";
import PosterDetailCarousel from "../PosterDetailCarousel";
import { BREAKPOINTS } from "../../../../common/constant";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

const PosterCarousel = ({
  movie,
  handleModal,
  freeMode,
}: {
  movie: Movie;
  handleModal: (type: string) => any;
  freeMode?: boolean;
}) => {
  const classes = useStyle();
  const isDesktop = useMediaQuery(BREAKPOINTS.desktop);
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const onNavigateDetail = (movie: Movie) => {
    if (freeMode) {
      history.push({
        pathname: "/details-festival",
        search: `?id=${movie.mo_id}`,
      });
    } else {
      history.push({
        pathname: "/details",
        search: `?id=${movie.mo_id}`,
      });
    }
  };

  return (
    <div
      ref={containerRef}
      className={classes.imageContainer}
      onClick={() => !isDesktop && onNavigateDetail(movie)}
      onMouseEnter={() => {
        if (isDesktop) {
          setHover(true);
          setAnchorEl(containerRef.current);
        }
      }}
      onMouseLeave={() => {
        if (isDesktop) {
          setHover(false);
          setAnchorEl(null);
        }
      }}>
      <img
        style={{ display: loading ? "none" : "block" }}
        className={`${classes.imagePoster} ${
          hover && classes.imagePosterHover
        }`}
        src={
          !isDesktop
            ? movie.mo_thumbnail.replace("_150x260", "")
            : movie.mo_thumbnail
        }
        alt={movie.mo_title}
        onLoad={() => setLoading(false)}
      />
      <Popper
        open={hover}
        anchorEl={anchorEl}
        placement="right-start"
        style={{ zIndex: 1000 }}>
        <Grow in={hover}>
          <div
            onMouseEnter={() => setHover(true)}
            className={classes.detailContainer}>
            <PosterDetailCarousel
              movie={movie}
              handleModal={handleModal}
              freeMode={freeMode}
            />
          </div>
        </Grow>
      </Popper>
      <Loader show={loading} style={{ aspectRatio: "9/14" }} />
    </div>
  );
};

export default PosterCarousel;
