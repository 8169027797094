import React, { useState } from "react";
import {
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Chip,
  MenuItem,
  Button,
  Typography,
  Divider,
  FormHelperText,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { editUserSchema } from "../schemas/validators";

const EditUserContent = ({
  user,
  subscriptions,
  onClose,
  handleEditUser,
  recoverPasswordEmail,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickPassword = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Formik
      initialValues={{
        subscriptionValue: user.subscriptions.map(sub => sub.su_id),
        notas: user.us_notes || "",
        password: "",
        reEnterPassword: "",
      }}
      validationSchema={editUserSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        handleEditUser(user, values, setSubmitting);
      }}>
      {({ handleSubmit, handleChange, values, errors, isSubmitting }) => {
        return (
          <form
            onSubmit={handleSubmit}
            spellCheck={false}
            noValidate
            autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Nombre y apellido"
                  value={`${user.us_name} ${user.us_lastname}`}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  value={user.us_email}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={errors.subscriptionValue ? true : false}>
                  <InputLabel id="subscription-select">
                    Membresía del usuario
                  </InputLabel>
                  <Select
                    labelId="subscription-select"
                    multiple
                    value={values.subscriptionValue}
                    onChange={handleChange("subscriptionValue")}
                    input={<OutlinedInput label="Membresía del usuario" />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getcontentanchorel: null,
                    }}
                    renderValue={selected => (
                      <Box
                        style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((subId, i) => (
                          <Chip
                            key={i}
                            label={
                              subscriptions?.find(sub => sub.su_id === subId)
                                .su_name
                            }
                          />
                        ))}
                      </Box>
                    )}>
                    {subscriptions.map(sub => (
                      <MenuItem key={sub.su_id} value={sub.su_id}>
                        <Checkbox
                          checked={
                            values.subscriptionValue.indexOf(sub.su_id) > -1
                          }
                        />
                        <ListItemText primary={sub.su_name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.subscriptionValue}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={errors.notas ? true : false}
                  helperText={errors.notas}
                  label="Notas sobre el usuario"
                  minRows={5}
                  multiline
                  fullWidth
                  value={values.notas}
                  onChange={handleChange("notas")}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  label="Contraseña"
                  fullWidth
                  value={values.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  error={errors.reEnterPassword ? true : false}
                  helperText={errors.reEnterPassword}
                  label="Reingresar contraseña"
                  fullWidth
                  value={values.reEnterPassword}
                  onChange={handleChange("reEnterPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Luego de cambiar la contraseña se le enviara un mail al
                  cliente con nueva contraseña siempre y cuando el mail este
                  registrado y sea válido
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: 15 }}
                  onClick={() => {
                    recoverPasswordEmail(user.us_email);
                  }}>
                  Enviar resteo de contraseña
                </Button>
                <Typography>
                  Se enviará un mail al cliente con un link para cambiar su
                  contraseña siempre y cuando el mail este registrado y sea
                  válido
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Button disableElevation color="primary" onClick={onClose}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    type="submit">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditUserContent;
