import { API } from "./APIConnector";

export const getSubscriptions = async () => {
  const response = await fetch(API + "/subscription");
  const data = await response.json();
  return data;
};

export const getMercadoPagoPlan = async id => {
  const response = await fetch(API + `/subscriptions/mercadopago-plan/${id}`);
  const data = await response.json();
  return data;
};

export const getPayPalPlan = async id => {
  const response = await fetch(API + `/subscriptions/paypal-plan/${id}`);
  const data = await response.json();
  return data;
};

export const createSubscription = async body => {
  const response = await fetch(API + `/subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export const deleteSubscription = async id => {
  const response = await fetch(API + `/subscription/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};

export const verifyUserSubscription = async body => {
  const response = await fetch(API + `/user/verify-payment-subscription`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};
