import React, { Fragment, useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavigationTitle from "../components/NavigationTitle";
import { Grid, Paper, TextField, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { createGenreSchema } from "../schemas/validators";
import { createGenre } from "../provider/GenreService";
import ModalMui from "../components/ModalMui";
import LoadingMsgModal from "../components/LoadingMsgModal";

const useStyles = makeStyles({
  paper: {
    padding: 30,
    marginBottom: 50,
    width: "100%",
  },
  radio: {
    margin: 0,
  },
  spacingVertical: {
    marginTop: 20,
    marginBottom: 10,
  },
  spacingHorizontal: {
    marginLeft: 10,
    marginRight: 10,
  },
  modalInfoBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  paperContent: {
    padding: 40,
  },
  paperContainer: {
    padding: 10,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    paddingBottom: 50,
  },
  btnCancel: {
    backgroundColor: "white",
    color: "red",
  },
});

const UploadGenre = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialValues = {
    name: "",
    type: null,
  };
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState({
    message: "",
    open: false,
    callBack: () => {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (values, resetForm) => {
    isLoading("Creando género...");
    try {
      const response = await createGenre(values);
      if (response.code === 200) {
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Género creado con éxito!",
          callBack: () => {
            resetForm();
            handleCloseInfoModal();
          },
        }));
      } else {
        isNotLoading();
        setInfoModal(prevState => ({
          ...prevState,
          open: true,
          message: "Ocurrió un error al crear el género",
          callBack: () => {
            handleCloseInfoModal();
          },
        }));
      }
    } catch (err) {
      console.log(err);
      isNotLoading();
      setInfoModal(prevState => ({
        ...prevState,
        open: true,
        message: "Ocurrió un error al crear el género",
        callBack: () => {
          handleCloseInfoModal();
        },
      }));
    }
  };

  const isLoading = (message = "") => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: true,
      message,
    }));
  };

  const isNotLoading = () => {
    setLoadingModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
    }));
  };

  const handleCloseInfoModal = () => {
    setInfoModal(prevState => ({
      ...prevState,
      open: false,
      message: "",
      callBack: () => {},
    }));
  };

  return (
    <Fragment>
      <Header />
      <NavigationTitle
        title={"Agregar género"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
      />
      <LoadingMsgModal
        open={loadingModal.open}
        loadingMessage={loadingModal.message}
      />
      <ModalMui open={infoModal.open} handleClose={() => {}} maxWidth="xs">
        <Box className={classes.modalInfoBox}>
          <Typography variant="h6" style={{ color: "black" }}>
            {infoModal.message}
          </Typography>
        </Box>
        <Box className={classes.modalInfoBox}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={infoModal.callBack}>
            Aceptar
          </Button>
        </Box>
      </ModalMui>
      <Grid
        container
        justifyContent="center"
        className={classes.paperContainer}>
        <Grid item xs={12} md={10} lg={8} xl={8}>
          <Paper className={classes.paperContent}>
            <Formik
              initialValues={initialValues}
              validateOnBlur={formIsDirty}
              validateOnChange={formIsDirty}
              validationSchema={createGenreSchema}
              onSubmit={(values, { resetForm }) => {
                values = {
                  ...values,
                  ge_name: values.name,
                  ge_type: values.type,
                };
                onSubmit(values, resetForm);
              }}>
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form
                  onSubmit={e => {
                    setFormIsDirty(true);
                    handleSubmit(e);
                  }}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography>Nombre</Typography>
                      <TextField
                        placeholder="Nombre del genero"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography>Tipo</Typography>
                      <Autocomplete
                        fullWidth
                        noOptionsText="Sin opciones"
                        options={["Género", "Tema", "Xiclos"]}
                        value={values.type}
                        onChange={(e, value) => {
                          setFieldValue(
                            "type",
                            value !== null ? value : initialValues.type,
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Género"
                            variant="outlined"
                            error={errors.type ? true : false}
                            helperText={errors.type && errors.type}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs
                      justifyContent="flex-end"
                      className={classes.spacingVertical}>
                      <button
                        type="button"
                        className={`btn btn-default ${classes.btnCancel}`}
                        onClick={() => history.goBack()}>
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-hover">
                        Agregar
                      </button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default UploadGenre;
