import { API, HEADERS, HeadersBearer } from "./APIConnector";

export const addList = async id => {
  let user = JSON.parse(localStorage.getItem("U"));
  let userId = user.us_id;

  let request = {
    method: "POST",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fa_mo_id: id,
      fa_us_id: userId,
    }),
  };

  const res = await fetch(API + "/list/add", request);

  const data = await res.json();

  return data;
};

export const removeList = async id => {
  let user = JSON.parse(localStorage.getItem("U"));
  let userId = user.us_id;

  let request = {
    method: "DELETE",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fa_mo_id: id,
      fa_us_id: userId,
    }),
  };

  const res = await fetch(API + "/list/remove", request);

  const data = await res.json();

  return data;
};

export const fetchList = async () => {
  let userId = JSON.parse(localStorage.getItem("U")).us_id;
  let request = {
    method: "GET",
    headers: {
      ...HEADERS(),
      "Content-Type": "application/json",
    },
  };

  const res = await fetch(API + `/list/${userId}`, request);

  const data = await res.json();

  return data;
};

export const getMyList = async signal => {
  const data = await fetch(`${API}/v2/login/my-list`, {
    method: "GET",
    headers: HeadersBearer(),
    signal,
    cache: "default",
  });
  const result = await data.json();

  return { count_movies: result.data?.length || 0, movies: result.data || [] };
};

export const getContinueWatching = async signal => {
  const data = await fetch(`${API}/v2/login/history`, {
    method: "GET",
    headers: HeadersBearer(),
    signal,
    cache: "default",
  });
  const result = await data.json();

  return result.data;
};
