/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACT_BANNER_CHANGE } from "../actions/ActionTypes";

const initialState = {
  loadingBanners: true,
  banners: [],
  firstFetch: true,
};

export default function (
  state = initialState,
  action: { type: string; props: any },
) {
  if (action.type === ACT_BANNER_CHANGE) {
    return Object.assign({}, state, action.props);
  }
  if (action.type === "ACT_BANNER_FIRST_FETCH") {
    return { ...state, firstFetch: false };
  }
  return state;
}
