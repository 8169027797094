import { LARGE_DEVICE, SMALL_DEVICE } from "./constant";

export const getDeviceType = () => {
  const width = document.body.clientWidth;
  // console.log("getDeviceType", width)
  if (width > 975) {
    return LARGE_DEVICE;
  }
  return SMALL_DEVICE;
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = pass => {
  let passwordHaveNum = false;
  let passwordHaveChar = false;
  pass.split("").forEach(char => {
    if (isNaN(char)) {
      passwordHaveChar = true;
    }
    if (Number.isInteger(parseInt(char))) {
      passwordHaveNum = true;
    }
  });

  if (pass.length < 8 || !(passwordHaveChar && passwordHaveNum)) {
    return false;
  }
  return true;
};

export const scrollToTop = () => {
  document.querySelector("body").scrollIntoView({ behavior: "smooth" });
};

export const validateSliders = (localSliders, sliders) => {
  let isDifferent = false;
  if (localSliders.length !== sliders.length) {
    return sliders;
  }

  for (let i = 0; i < sliders.length; i++) {
    let slider = sliders[i];
    let localSlider = localSliders[i];

    if (slider.sl_id !== localSlider.sl_id) {
      isDifferent = true;
      break;
    }
    if (slider.sl_img !== localSlider.sl_img) {
      isDifferent = true;
      break;
    }
    if (slider.count_movies !== localSlider.count_movies) {
      isDifferent = true;
      break;
    }
    if (slider.sl_order !== localSlider.sl_order) {
      isDifferent = true;
      break;
    }
    if (slider.sl_name !== localSlider.sl_name) {
      isDifferent = true;
      break;
    }
    if (slider.count_movies !== localSlider.count_movies) {
      isDifferent = true;
      break;
    }
  }

  if (isDifferent) {
    return sliders;
  } else {
    return null;
  }
};

export const trimString = (string, length) => {
  if (string === undefined || string == null) {
    return "";
  }
  if (Object.keys(string).length === 0) {
    return "";
  }
  if (string.length > length) {
    let arrayString = string
      .substring(0, length - 4)
      .trim()
      .split(" ");
    return arrayString.slice(0, arrayString.length - 1).join(" ") + " ...";
  } else {
    return string;
  }
};

export const subscriptionStateData = user => {
  let subscriptionStatus = "";
  switch (user.us_suscription_status) {
    case "active":
      subscriptionStatus = "ACTIVA";
      break;

    case "inactive":
      subscriptionStatus = "INACTIVA";
      break;

    case "pending":
      subscriptionStatus = "PENDIENTE";
      break;

    case "free":
      subscriptionStatus = "FREE";
      break;

    default:
      subscriptionStatus = "-";
      break;
  }
  return subscriptionStatus;
};

export const billingStateData = user => {
  let billingStatus = "";
  switch (user.us_billing.bi_payment_status) {
    case "accredited":
      billingStatus = "ACREDITADO";
      break;

    case "pending":
      billingStatus = "PENDIENTE";
      break;

    case "accepted":
      billingStatus = "ACEPTADO";
      break;

    case "rejected":
      billingStatus = "RECHAZADO";
      break;

    default:
      billingStatus = "-";
      break;
  }
  return billingStatus;
};

export const getMovieData = item => {
  let directorsArray = [];
  item.directors.forEach(item => {
    directorsArray.push(item.di_name + " " + item.di_lastname);
  });
  let directors = "";
  if (directorsArray.length > 0) {
    directors = directorsArray.join(", ");
  }

  let genreArray = [];
  item.genre.forEach(item => {
    genreArray.push(item.ge_name);
  });
  let genre = "";
  if (genreArray.length > 0) {
    genre = genreArray.join(", ");
  }

  let actorArray = [];
  item.actor.forEach(item => {
    actorArray.push(item.ac_name + " " + item.ac_lastname);
  });
  let actors = "";
  if (actorArray.length > 0) {
    actors = actorArray.join(", ");
  }

  let countriesArray = [];
  item.country.forEach(item => {
    countriesArray.push(item.co_name);
  });
  let countries = "";
  if (countriesArray.length > 0) {
    countries = countriesArray.join(", ");
  }

  const data = {
    mo_id: item.mo_id,
    mo_title: item.mo_title,
    mo_thumbnail: item.mo_thumbnail,
    mo_image_fullsize: item.mo_image_fullsize,
    mo_description: trimString(item.mo_description, 115),
    mo_year: item.mo_year,
    mo_imdb_rating: item.mo_imdb_rating,
    mo_mpa_rating: item.mo_mpa_rating,
    mo_duration: item.mo_duration,
    directors: directors,
    genre: trimString(genre, 24),
    actors: trimString(actors, 50),
    countries: trimString(countries, 50),
  };

  return data;
};
