import * as yup from "yup";

export const editUserSchema = yup.object().shape({
  notas: yup.string().max(150, "Máximo 150 caracteres"),
  password: yup
    .string()
    .min(4, "Mínimo 4 caracteres")
    .max(8, "Máximo 8 caracteres"),
  reEnterPassword: yup
    .string()
    .min(4, "Mínimo 4 caracteres")
    .max(8, "Máximo 8 caracteres")
    .oneOf([yup.ref("password"), null], "Las contraseñas deben ser iguales"),
  subscriptionValue: yup.array().min(1, "Mínimo 1 membresía"),
});

export const createBannerSchema = yup.object().shape({
  title: yup
    .string()
    .max(80, "Máximo 80 caracteres")
    .required("Titulo requerido"),
  description: yup
    .string()
    .max(380, "Máximo 380 caracteres")
    .required("Descripción requerida"),
  image: yup.mixed().required("Imagen del banner requerida"),
  linkBanner: yup
    .string()
    .max(180, "Máximo 180 caracteres")
    .required("Link banner requerido"),
  buttonLabel: yup
    .string()
    .max(40, "Máximo 40 caracteres")
    .required("Nombre botón requerido"),
});

export const createMovieBannerSchema = yup.object().shape({
  title: yup
    .string()
    .max(80, "Máximo 80 caracteres")
    .required("Titulo requerido"),
  description: yup
    .string()
    .max(380, "Máximo 380 caracteres")
    .required("Descripción requerida"),
  buttonLabel: yup
    .string()
    .max(40, "Máximo 40 caracteres")
    .required("Nombre botón requerido"),
  movie: yup.mixed().required("Película es requerido"),
});

export const createGenreSchema = yup.object().shape({
  name: yup
    .string()
    .max(80, "Máximo 40 caracteres")
    .required("Nombre es requerido"),
  type: yup.mixed().required("Tipo es requerido"),
});

export const createSubscriptionSchema = yup.object().shape({
  cupon: yup
    .string()
    .min(3, "Minimo 3 caracteres")
    .max(40, "Máximo 40 caracteres")
    .required("Cupón es requerido"),
  description: yup
    .string()
    .min(3, "Minimo 3 caracteres")
    .max(40, "Máximo 40 caracteres")
    .required("Descripción es requerida"),
  mpPlanId: yup
    .string()
    .max(250, "Máximo 250 caracteres")
    .required("Link de mercadopago requerido"),
  paypalPlanId: yup
    .string()
    .max(250, "Máximo 250 caracteres")
    .required("Link de paypal requerido"),
  priceArs: yup.string().required("Precio ARS requerido"),
  priceUsd: yup.string().required("Precio USD requerido"),
});

export const createFreeUserSchema = yup.object().shape({
  name: yup
    .string()
    .max(90, "Máximo 90 caracteres")
    .required("Nombre requerido"),
  lastname: yup
    .string()
    .max(90, "Máximo 90 caracteres")
    .required("Apellido requerido"),
  email: yup
    .string()
    .email("Debe tener formato de correo")
    .max(90, "Máximo 90 caracteres")
    .required("Email botón requerido"),
  country: yup.string().required("País requerido"),
  state: yup.string().required("Region/Provincia requerido"),
  city: yup.string().required("Ciudad/Localidad requerido"),
  zipcode: yup
    .string()
    .max(20, "Máximo 20 caracteres")
    .required("Código postal requerido"),
  subscription: yup.string().required("Suscripción requerida"),
  expDate: yup.date().nullable(),
});

export const deleteBannerSchema = yup.object().shape({
  bannerSelected: yup.array().min(1, "Debe seleccionar un banner").nullable(),
});

export const deleteSlidersSchema = yup.object().shape({
  slidersSelected: yup.array().min(1, "Debe seleccionar al menos una tira"),
});

export const deleteGenresSchema = yup.object().shape({
  genresSelected: yup.array().min(1, "Debe seleccionar al menos un genero"),
});

export const deleteMoviesSchema = yup.object().shape({
  moviesSelected: yup.array().min(1, "Debe seleccionar al menos una película"),
});

export const editMovieMetadataSchema = yup.object().shape({
  title: yup.string().max(60, "Máximo 60 caracteres"),
  description: yup.string().max(160, "Máximo 160 caracteres"),
});
