import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    gap: "15px",
    paddingBottom: 50,
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
  },
  loading: {
    backgroundImage:
      "linear-gradient(to right, rgb(26, 26, 26) 0%, rgb(52, 52, 51) 49%, rgb(26, 26, 26) 100%)",
    minHeight: "40vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
