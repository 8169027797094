import React, { CSSProperties } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ASPECT_RATIO_POSTER } from "../../../common/constant";

interface LoaderProps {
  show: boolean;
  style?: CSSProperties;
  className?: string;
}

const Loader = ({ show, style, className }: LoaderProps) => {
  if (show) {
    return (
      <Box
        className={className}
        sx={{
          width: "100%",
          height: "100%",
          aspectRatio: ASPECT_RATIO_POSTER,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(26, 26, 26, 0.9)",
          borderRadius: 2,
          ...style,
        }}>
        <CircularProgress />
      </Box>
    );
  }
  return null;
};

export default Loader;
