import React, { useState } from "react";

import "../css/reset-password.scss";

import { useHistory, Link } from "react-router-dom";
import firebase from "firebase";
import FooterLogin from "../components/FooterLogin";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = event => {
    setError(false);
    setSuccess(false);
    setEmail(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    setLoading(true);
    firebase.auth().languageCode = "es";
    firebase
      .auth()
      .sendPasswordResetEmail(email.trim())
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          history.push("/login");
        }, 5000);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        switch (err.code) {
          case "auth/invalid-email":
            setError({
              error: true,
              message: "El email ingresado no es valido",
            });
            break;
          case "auth/user-not-found":
            setError({
              error: true,
              message:
                "El email ingresado no se encuentra registrado en Xiclos",
            });
            break;
          default:
            setError({ error: true, message: "Ocurrió un error inesperado" });
            break;
        }
      });
  };

  return (
    <div className="reset-password">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Recuperar contraseña - Xiclos</title>
        <meta
          name="description"
          content="Página de recuperación de contraseñas de usuario"
        />
      </Helmet>
      <div id="sign-in">
        <section
          className="sign-in-page animate__animated animate__fadeIn"
          style={{ minHeight: 0 }}>
          <div id="sign-in-back">
            <i
              className="ri-arrow-left-line"
              onClick={() => history.goBack()}></i>
          </div>
          <div className="sign-in-page-backdrop">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <div className="col-lg-5 col-md-12 ">
                  <div className="sign-user_card sign-user-card-style-data animate__animated animate__backInUp">
                    <div className="sign-in-page-data">
                      <div className="sign-in-from w-100 m-auto">
                        <h3
                          className="mb-3 text-center"
                          style={{
                            color: "#343433",
                            fontSize: "47px",
                            fontFamily: "Helvetica Neue Bold",
                          }}>
                          Recuperar contraseña
                        </h3>
                        <p className="sign-in-page-p">
                          Ingresá tu email y te enviaremos un link para iniciar
                          la recuperación de tu contraseña
                        </p>
                        <form className="mt-4" onSubmit={onSubmit}>
                          <div className="form-group">
                            <input
                              type="email"
                              onChange={handleChange}
                              value={email}
                              className="form-control mb-0 sign-user-card-style-data-form"
                              id="sign-in-email"
                              placeholder="Email"
                              autoComplete="on"
                              required
                            />
                          </div>
                          <div className="d-flex justify-content-center links">
                            <button
                              type="submit"
                              id="sign-in-reset"
                              className="btn btn-hover">
                              {!loading && !error.error ? (
                                "RECUPERAR CONTRASEÑA"
                              ) : error ? (
                                ":("
                              ) : (
                                <div>
                                  <i className="ri-loader-4-line" />
                                </div>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div
                        id="sign-in-errors"
                        style={{
                          color: "var(--iq-primary)",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="animate__animated animate__fadeIn mt-3 mb-0">
                        {success && (
                          <p style={{ color: "#059862" }}>
                            Si ha ingresado un correo válido, revise su casilla
                            de correo
                          </p>
                        )}
                        {error.error && <p>{error.message}</p>}
                      </div>
                    </div>
                    <div className="sign-user_card sign-user-card-style-data animate__animated animate__backInUp mt-4 user-information-recovery">
                      <div className="d-flex justify-content-center links">
                        <p style={{ marginBottom: 0 }}>
                          Si no recordás tu email, envianos tu nombre, apellido
                          y DNI por <br />
                          <Link
                            to="/contact"
                            className="text-primary ml-1 user-information-recovery">
                            este medio
                          </Link>{" "}
                          y nos pondremos en contacto a la brevedad!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 d-none d-lg-flex justify-content-center align-items-end sign-up-xiclos flex-column">
                  <img src="./images/logo.png" alt="" width="250" />
                  <h1 className="text-right h1 my-3">
                    Comenzá a disfrutar del mejor cine
                  </h1>
                  <h2 className="text-right h2">EL OTRO CINE</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterLogin
          copyRightText={`Copyright © 2021 - XICLOS - Todos los derechos reservados.`}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
