/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import "../css/panel-control-users.scss";
import {
  Paper,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import Header from "../components/Header";
import NavigationTitle from "../components/NavigationTitle";
import Footer from "../components/Footer";
import ModalMui from "../components/ModalMui";
import { Helmet } from "react-helmet";
import { getAllMovies } from "../provider/MoviesServices";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import { editMovieMetadataSchema } from "../schemas/validators";
import { updateMovieMetadata } from "../provider/MoviesServices";

function PanelControlSEO() {
  const [currentMovie, setCurrentMovie] = useState<any>(null);
  const [movies, setMovies] = useState([]);
  const [loadingModal, setLoadingModal] = useState({
    open: false,
    message: "",
  });
  const [infoModal, setInfoModal] = useState<{
    open: boolean;
    message: string | boolean;
  }>({
    open: false,
    message: "",
  });

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validateOnChange: true,
    validationSchema: editMovieMetadataSchema,
    onSubmit: values => onUpdateMovieMetadata(values),
  });

  useEffect(() => {
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "Obteniendo información de las películas...",
      };
    });
    getAllMovies()
      .then(res => {
        console.log(res);
        setMovies(res.data);
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
      })
      .catch(err => {
        console.log(err);
        setLoadingModal(prevState => {
          return {
            ...prevState,
            open: false,
            message: "",
          };
        });
      });
  }, []);

  const onUpdateMovieMetadata = async (values: any) => {
    setLoadingModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: "",
      };
    });
    try {
      await updateMovieMetadata((currentMovie as any).mo_id, values);
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: false,
          message: "",
        };
      });
      successCallback();
    } catch (err) {
      console.log(err);
      setLoadingModal(prevState => {
        return {
          ...prevState,
          open: false,
          message: "",
        };
      });
      errorCallback();
    }
  };

  const successCallback = (msg = null) => {
    setInfoModal(prevState => {
      return {
        ...prevState,
        open: true,
        message: msg || "Modificado con éxito",
      };
    });
  };

  const errorCallback = (msg = null) => {
    setInfoModal(prevState => {
      return { ...prevState, open: true, message: msg || "Ocurrió un error" };
    });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Panel de control de películas SEO - Xiclos</title>
        <meta
          name="description"
          content="Administrar películas de la aplicación"
        />
      </Helmet>
      <Header />
      <NavigationTitle
        title={"Administración de películas"}
        icon={"../images/icons/xiclos-icon-cyan@2x.png"}
        descriptionText={undefined}
      />
      <div className="panel-control-users">
        <ModalMui
          open={loadingModal.open}
          handleClose={() => {}}
          maxWidth="xs"
          actions={undefined}
          title={undefined}
          close={undefined}
          fullWidth={undefined}>
          <Box
            display="flex"
            justifyContent="center"
            style={{ minHeight: 130 }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: 20,
                paddingBottom: 20,
              }}>
              <Typography variant="h6" style={{ color: "black" }}>
                {loadingModal.message}
              </Typography>
            </Box>
            {!loadingModal.message && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}>
                {/* <Typography variant="h5" style={{ color: "black" }}>
                Cargando...
              </Typography> */}
                <CircularProgress color="primary" size={52} />
              </Box>
            )}
          </Box>
        </ModalMui>
        <ModalMui
          open={infoModal.open}
          handleClose={() => {}}
          maxWidth="xs"
          actions={undefined}
          title={undefined}
          close={undefined}
          fullWidth={undefined}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Typography variant="h6" style={{ color: "black" }}>
              {infoModal.message}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
              marginBottom: 20,
            }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                setInfoModal(prevState => {
                  return { ...prevState, open: false, message: false };
                });
              }}>
              Aceptar
            </Button>
          </Box>
        </ModalMui>

        <Grid container justifyContent="center" className="paper-container">
          <Grid item xs={12} md={10} lg={8} xl={7}>
            <Paper className="paper-content">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: "black" }}>
                      Busque por nombre o id y seleccione la película a
                      modificar
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      value={currentMovie}
                      onChange={(event, newValue: any) => {
                        setCurrentMovie(newValue);
                        if (newValue?.mo_title_metadata) {
                          setFieldValue("title", newValue?.mo_title_metadata);
                        } else {
                          setFieldValue("title", newValue.mo_title);
                        }

                        if (newValue?.mo_description_metadata) {
                          setFieldValue(
                            "description",
                            newValue?.mo_description_metadata,
                          );
                        } else {
                          setFieldValue(
                            "description",
                            newValue?.mo_description,
                          );
                        }
                      }}
                      getOptionLabel={(option: any) =>
                        `${option?.mo_title} - ID: ${option.mo_id}`
                      }
                      options={movies}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Películas"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {currentMovie && (
                    <Fragment>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{ color: "black", marginRight: "10px" }}
                          display="inline">
                          Titulo:
                        </Typography>
                        <Typography display="inline">
                          {currentMovie?.mo_title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        <Typography
                          variant="h6"
                          style={{ color: "black", marginRight: "10px" }}
                          display="inline">
                          Descripción:
                        </Typography>
                        <Typography display="inline">
                          {currentMovie?.mo_description}
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                      label="Titulo - Metadata"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: "100%" }}
                      label="Descripción - Metadata"
                      variant="outlined"
                      minRows={3}
                      multiline
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      style={{ marginRight: "10px" }}>
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      type="submit">
                      Modificar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}

export default PanelControlSEO;
